import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AccordianSection from '../components/AccordianSection'

const Faq = () => {
  return (
    
    <>
    <Header/>
    <div className='section-padding padding-top'>
<AccordianSection/>
    </div>
 
    <Footer/>  
    </>
  )
}

export default Faq

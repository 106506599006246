import React from "react";
// import { NavLink } from "react-router-dom";

const Map = () => {
  return (
    <section className="section-padding mission-wrapper primary-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="map-wrapper">
              <img src="/assets/images/Map.png" alt="current_data" />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="map-wraper-content about-section-wrapper">
              {/* <span className="secondary-color-text ">Social Media</span> */}
              <h3 style={{ marginTop: "10px" }}>
                Come say hello at our{" "}
                <span className="secondary-color-text"> Office HQ</span>
              </h3>
              <p>
              2nd Floor, SCO-15, Sector-31, Gurugram (Hr.)-122002
              </p>
              <a
                href="https://maps.app.goo.gl/dN3zk6n35QysJ1vT9?g_st=ic"
                rel="noopener noreferrer"
                className="button-content w-50 text-white"
               target="_blank"
              >
                Get Direction
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Map;

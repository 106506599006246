import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Steps from '../StepsPage/Steps';
import Header from '../Header';
import Footer from '../Footer';
import Spinner from "../SpinnerData";
import api from "../../axios/api"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAadhaar } from "../../store/sliceadhar";

const KycPan = () => {
  const navigate = useNavigate();
  const [loadingdata, setLoadingData] = useState(false);
  const [panCard, setPanCard] = useState(''); 
  const authToken = useSelector((state) => state.token.token);
  const dispatch = useDispatch();

  const handleData = async (e) => {
    e.preventDefault();
    
    try {
      setLoadingData(true);
      const response = await api.post('pan-verification', {
        pan: panCard 
      },authToken);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        dispatch(setAadhaar(response.data)); 
        setLoadingData(false);
        toast.success("PAN verified successfully");
        navigate("/qr-code");
      } else {
        setLoadingData(false);
        toast.error(`Verification failed: ${response.data.message || 'Unknown error'}`);
      }
    } catch (error) {
      setLoadingData(false);
      console.error('An error occurred:', error);
      toast.error("An error occurred while verifying PAN");
    }
  };

  return (
    <div>
      <Header/>
      <ToastContainer autoClose={5000} />
      {loadingdata && <Spinner />}
      <div className='section-padding kyc-wrapper-data'>
        <Steps isKyc={true} />
        <div>
          <form onSubmit={handleData}>
            <div className='col-lg-12'>
              <div className='card' style={{width:"40%", margin:"0 auto"}}>
                <div className='card-body'>
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="panCard" className="dataOne">Add PAN Card</label>
                    <input
                      type="text"
                      id="panCard"
                      className="form-control"
                      placeholder="Enter your PAN Card number"
                      value={panCard}
                      onChange={(e) => setPanCard(e.target.value)} // Handle input change
                    />
                  </div>
                  <button type="submit" className='button-content mx-auto mt-3'>Add PAN Card</button>
                </div>
              </div>
            </div>
          </form>
        </div>    
      </div>
      <Footer/>
    </div>
  );
}

export default KycPan;

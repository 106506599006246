import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
// import Offering from '../components/Offering'

import AccordianSection from "../components/AccordianSection";
// import Banner2 from "../components/AboutPage/Banner2";
import Eligable from "../components/BenefitPage/Eligable";
// import BulkWork from "../components/BenefitPage/BulkWork";
import PenCardForm from "../components/PenCardPage/PenCardForm";
import PenOtp from "../components/PenCardPage/PenOtp";
import PenCongrats from "../components/PenCardPage/PenCongrats";


const PenCard = () => {
  const [penCard, setPenCard] = useState(true);
  const [otpshow, setOtpShow] = useState(false);
  const [pendetail, setPenDetail] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <div>
      <Header />
      {/* <Banner2 /> */}
      <div className="mission-wrapper primary-color  mt-0 pan-wrapper-data">
        {/* <h1 className="text-center content-heading">
          <b className="secondary-color-text">Get loan against mutual funds.</b>{" "}
          FREE eligibility check in 15 seconds
        </h1> */}
        {penCard && (
          <PenCardForm
            setPenCard={setPenCard}
            setOtpShow={setOtpShow}
            setPhoneNumber={setPhoneNumber}
          />
        )}
        {otpshow && (
          <PenOtp
            setPenDetail={setPenDetail}
            setOtpShow={setOtpShow}
            phoneNumber={phoneNumber}
          />
        )}
       
        {pendetail && <PenCongrats />}
      </div>
      <Eligable />
      {/* <BulkWork /> */}
      <AccordianSection />
      <Footer />
    </div>
  );
};

export default PenCard;

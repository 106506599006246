import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch,useSelector } from 'react-redux';
import Spinner from "../SpinnerData";
import api from "../../axios/api"; 
import Footer from '../Footer';
import Header from '../Header';
import Steps from '../StepsPage/Steps';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAadhaar } from "../../store/sliceadhar";
const KycOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [loadingdata, setLoadingData] = useState(false);
  const authToken = useSelector((state) => state.token.token);
  const refId = useSelector((state) => state.aadhaar.aadhaar.ref_id); 
  const dispatch=useDispatch();
  console.log("refId===========>",refId);
  const handleAdd = async (e) => {
    e.preventDefault();
    
    try {
      setLoadingData(true);
      const response = await api.post('aadhar-verify',{
        otp:otp,
        ref_id: refId
      },authToken);
      console.log("API Response:", response.data);
      if (response.status === 200) {
        dispatch(setAadhaar(response.data)); 
        setLoadingData(false);
        toast.success("Aadhaar Verify successfully");
        navigate('/kyc-pan');
      } else {
        setLoadingData(false);
        console.error('Aadhaar Verify failed:', response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      console.error('An error occurred:', error);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer autoClose={5000} />
      {loadingdata && <Spinner />}
      <div className='section-padding kyc-wrapper-data '>
        <Steps isKyc={true} />
        <div className='card' style={{ width: "40%", margin: "0 auto" }}>
          <div className="card-body">
            <form onSubmit={handleAdd}>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="otpNumber" className="dataOne">OTP Number</label>
                    <input
                      type="text"
                      id="otpNumber"
                      className="form-control"
                      placeholder="Enter your OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                </div>
                <div className='wrapper-data-otp'>
                  <div></div>
                  <button type="submit" className='button-content mt-3 w-25 mx-auto'>Submit</button>
                  {/* Uncomment if you want to implement OTP resend functionality
                  <div className='mt-3 resend-otp'>
                    <NavLink onClick={ResendKyc} className="text-content">Resend OTP</NavLink>
                  </div>
                  */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default KycOtp;


import tokenReducer from './slice';
import dataReducer from "./dataslice";
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import aadhaarReducer from './sliceadhar';
const persistConfig = {
  key: 'root',    
  storage,       
};
const persistedReducer = persistReducer(persistConfig, dataReducer);

const store = configureStore({
  reducer: {
    data: persistedReducer,
    token:tokenReducer,
    aadhaar: aadhaarReducer, 

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});
const persistor = persistStore(store);
export { store, persistor };

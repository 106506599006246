import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Offering from '../components/Offering'
import AccordianSection from '../components/AccordianSection'
// import Banner3 from '../components/BenefitPage/Banner3'
import Eligable from '../components/BenefitPage/Eligable'
// import BulkWork from '../components/BenefitPage/BulkWork'

const Benefit = () => {
  return (
    <>
      <Header/>
      {/* <Banner3/> */}
      <div className='primary-color pt-3'>
      <Offering/>
      </div>
      <Eligable/>
      {/* <BulkWork/> */}
      <AccordianSection/>
      <Footer/>
    </>
  )
}

export default Benefit

import React, { useState } from "react";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import api from "../../axios/api";
import { storeData } from "../../store/dataslice";
import Spinner from "../SpinnerData";
const PenCongrats = () => {
  const [loadingdata, setLoadingData] = useState(false);
  const eligableData = useSelector((state) => state.data);
  const latesteligable = eligableData.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      source: "/assets/images/persent.png",
      heading: "11% per annum (Reducing)",
      title: "EMI & Interest Only Payments",
    },
    {
      id: 2,
      source: "/assets/images/timer.png",
      heading: "Zero Prepayment Charges",
      title: "Repay Or Close Your Loan Anytime",
    },
    {
      id: 3,
      source: "/assets/images/rupee.png",
      heading: "Easily get Cash for urgent needs",
      title: "While Earning Interest On Your Mutual Funds",
    },
  ];
  var formattedAmountWithCommas;

  if (latesteligable && typeof latesteligable.eligibleLoanAmount === "string") {
    const cleanedAmount = latesteligable.eligibleLoanAmount.replace(/,/g, "");
    const eligibleAmount = Number(cleanedAmount) * 0.45;

    const formattedAmount = eligibleAmount.toFixed(2);
    formattedAmountWithCommas = formattedAmount.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  }
  const handleClick = async () => {
    try {
      setLoadingData(true);
      const dataPortfolio = {
        accessToken: eligableData.accessToken,
        signature: latesteligable.portfolio_signature,
        payload: latesteligable.portfolio_payload,
      };

      const response = await api.post(`portfolio`, dataPortfolio);
      dispatch(storeData(response));
      console.log("eligableData.accessToken",eligableData.accessToken);
      if (response.status === 200) {
        if(eligableData.accessToken){
          navigate("/kyc");
        toast.success("Portfolio successfully");
        }else{
          navigate("/login");
        toast.error("Please Complete Your Kyc and login");
        } 
        
      }
    } catch (error) {
      setLoadingData(false);
      console.log("error occurred in verify-otp", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoadingData(false);
    }
  };
  return (
    <div className="congrats-data">
      <ToastContainer autoClose={500} className="data-toast" />
      {loadingdata && <Spinner />}
      <div className="congrats-wrapper-data">
        <p className="text-center">
          <b>CONGRATULATIONS,</b> You Are Eligable
        </p>
        <h4 className="text-content-data text-center">
          ₹ {formattedAmountWithCommas}/-
          <img
            src="/assets/images/i2.png"
            alt="current-data"
            className="img-data"
          />
        </h4>
        <button
          type="submit"
          className="button-content button-data-wrapper mt-4"
          onClick={handleClick}
        >
          Apply
        </button>
      </div>
      <div className="congtrass-subwrapper">
        <div className="congtrass-data">
          <h5>Bullwark Capital's Benefits</h5>
          {data.map((item) => (
            <div className="congrats-data-content" key={item.id}>
              <div className="congrats-image-wrapper">
                <img
                  src={item.source}
                  alt="current-data"
                  style={{ width: "48px", height: "48px" }}
                />
              </div>
              <div className="mt-2">
                <h6 className="font-size-data mb-0">{item.heading}</h6>
                <p className="font-size-data">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="congtrass-data mt-5">
          <h5>Credit Limit for Your Portfolio</h5>
          <div className="congrts-total">
            <p className="font-size-data">
              <b>Total Portfolio</b>
            </p>
            <p className="font-size-data">
              {latesteligable.eligibleLoanAmount}
            </p>
          </div>
          <div className="congrats-total-wrapper">
            <div className="data-content">
              <p className="font-size-data">
                <b>Eligible Portfolio</b><br/>
                <span>
                  1.Some Of Your Investement May Not be Avilable For Pledging If
                  They Are In DEMAT from And are not Aggregated By Kfintech And CAMS
                </span><br/>
                <span>2.ELSS/Tax Saving Mutual Funds Are Not Included And Can Not Pledged</span> <br/>
                <span>3.Assets That Are Already Pledged Will Not Be Shown In The Table </span>
              </p>
              <p className="font-size-data">
                {latesteligable.eligibleLoanAmount}
              </p><br/>
             
            </div>
            <div className="data-content">
              <p className="font-size-data">
                <b>Eligible Limit</b>
                <p>Eligible Limit is 45% value of the Eligible Portfolio </p>
                <span>As per RBI guideline NBFC's shall maintain a security margin of 100% for the loans granted against securities at all times </span>
              </p>
              <p className="font-size-data text-content-data">
                {formattedAmountWithCommas}
              </p>
            </div>

            <button
              type="submit"
              className="button-content button-data-wrapper mt-4"
              onClick={handleClick}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="congtrass-data mt-5">
          <div className="congrats-data-content">
            <div className="congrats-image-wrapper">
              <img
                src="/assets/images/i.png"
                alt="current-data"
                style={{ width: "48px", height: "48px" }}
              />
            </div>
            <div className="mt-2">
              <h6 className="font-size-data mb-0">
                Fetched Portfolio for KDKPS7867D / 7683409252
              </h6>
              <p className="font-size-data">
                <NavLink>
                  Edit Details
                  <img
                    src="/assets/images/check-green.png"
                    alt="current-data"
                    className="img-data"
                  />
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PenCongrats;

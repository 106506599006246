import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Accordion from "react-bootstrap/Accordion";
const TermsCondition = () => {
  const data = [
    {
      id: 1,
      header: "TERMS AND CONDITIONS",
      body: `
          • We reserve the right to update or modify these T&Cs at any time. Your access and use of the Platform following any such change constitutes your agreement to follow and be bound by these T&Cs, as updated or modified. For this reason, we encourage you to review these T&Cs each time you access and use the Platform and/or avail our Services.
          `,
    },

    {
      id: 2,
      header: "PURPOSE",
      body: `
            • BULWARK CAPITAL’s Web & Mobile technology platform (Platform) offers various loan facilities. User can avail loans by completing the entire application process on the Platform itself. Further, the User can withdraw from the approved credit facility and repay the EMIs of the loan using the Platform itself.`,
    },
    {
      id: 3,
      header: "SERVICES",
      body: `
      • <strong>You understand that you have agreed to avail credit/loan facility with BULWARK CAPITAL.</strong> BULWARK CAPITAL will determine and approve the credit limit sanctioned to you, details of which shall be available on your Account. Subject to this credit limit, BULWARK CAPITAL may, at its sole discretion, allow disbursements, by confirming the disbursal amount and other terms and conditions of the disbursal (as determined by BULWARK CAPITAL) as set out on the Platform and in the Credit Facility Agreement.<br><br>
      • <strong>BULWARK CAPITAL shall provide the following facilities to you (“Services”):</strong><br>
          ◦ to facilitate the credit facility from BULWARK CAPITAL<br>
          ◦ to facilitate collection of equated monthly instalment (as determined by BULWARK CAPITAL), (either directly or its affiliates or through third party service providers) from you in relation to the credit facility you have obtained;<br>
          ◦ to create and maintain your Account in connection with the Services, on the Platform.<br><br>
      • As a service provider, BULWARK CAPITAL shall undertake communication, transaction and processing. All terms pertaining to your credit facility with BULWARK CAPITAL are set out in the Credit Facility Agreement.<br><br>
      • <strong>BULWARK CAPITAL reserves the right, without any prior notice to:</strong><br>
          (i) add new services;<br>
          (ii) modify existing Services;<br>
          and/or<br>
          (iii) remove portions of the Services, as and when it deems fit and at its sole discretion.<br>
      `,

    },
    {
      id: 4,
      header: "ELIGIBILITY",
      body: `
      • <strong>You represent and warrant that you are competent and eligible to enter into a legally binding agreement and be bound by these T&Cs.</strong> You shall not access and use the Platform or avail our Services if you are not competent to contract under the applicable laws, rules and regulations.<br><br>
      • For accessing the Platform and availing its Services, you, as a User, further represent that you are an Indian national having tax residency in India. You also represent and assure that you are not a tax resident of any other country.<br><br>
      • <strong>We maintain the right to render Services to only those users who are:</strong><br>
          a) competent to enter into legally binding contracts,<br>
          b) have made the representations as provided above,<br>
          c) and also qualify under the internal policy() for the same determined solely by us from time to time.<br><br>
        We shall have the sole right to change, modify, add or remove, in whole or in part, its internal policy(ies), in relation to the provision of the Services at any time without any prior written notice of intimation to the Users. Further, we shall have the right to not entertain any requests in relation to the same, from the Users towards such Services without assigning any reason.<br><br>
      • <strong>We shall have the right to not process a transaction through the Platform,</strong> if BULWARK CAPITAL in its sole discretion determines that such a transaction undertaken by a User is not authorized by such User or that the transaction is not genuine or suspicious.<br><br>
      • If you represent a company, partnership firm or sole proprietorship, you shall be eligible to access the Platform and avail of the features and facilities on its behalf only if you have been duly authorized by way of necessary corporate action, as may be prescribed statutorily and/or under the charter documents of such entity and further, if you have fulfilled such additional eligibility requirements as BULWARK CAPITAL may require in connection therewith.<br>
      `,
    },
    {
      id: 5,
      header: "REPRESENTATIONS, WARRANTIES AND UNDERTAKING",
      body: `
      • The information and data contained in the Platform do not constitute an offer to buy or sell or solicitation of an offer to buy or sell any Services in any jurisdiction other than India.<br><br>
      • All information provided by you to BULWARK CAPITAL is accurate, correct and true.<br><br>
      • You acknowledge and agree that BULWARK CAPITAL is entitled to remove any/all the information on the Platform that is in contravention of these T&Cs.<br><br>
      • You hereby authorize BULWARK CAPITAL, to make any enquiries with any other finance company/BCPL/registered credit bureau/mutual fund RTA/Broker/Depository regarding your credit history & investment details with them, in connection with the Services under these T&Cs.<br><br>
      • You agree to not engage in any activities pertaining to the Services that are contrary to any applicable law or regulation or the terms of any agreements you may have with BULWARK CAPITAL.<br><br>
      • You agree not to (i) create multiple Accounts or fake Accounts; (ii) create Accounts fraudulently; and/or (iii) use the Platform for any unlawful or immoral purpose.<br><br>
      • You understand that BULWARK CAPITAL does not provide any warranties for its Services and shall not be made liable for any claims made by you or any third party. In this regard, you undertake to accept and be solely liable for the Services availed by you from BULWARK CAPITAL. You further undertake to indemnify and hold harmless BULWARK CAPITAL, its affiliates and their respective directors, officers, employees, agents and representatives against all damages suffered or losses incurred by BULWARK CAPITAL arising due to any act, omission or claim initiated by you or any third party in relation to the Services availed by you or in case of breach of these T&Cs or any warranty by you.<br>
      `,
    },
    {
      id: 6,
      header: "COLLECTION",
      body: `
      • You agree to:<br>
          ◦ allow BULWARK CAPITAL (or its third party service providers), to send you payment reminders from time-to-time;<br>
          ◦ allow BULWARK CAPITAL (or its third party service providers), to engage in collection efforts to recover amounts that you failed to pay, pursuant to these T&Cs. These collection efforts may involve, inter alia, contacting either you directly or any other contact details provided by you at the time of creation of Account, submitting your information to a collection agency, or taking legal action.<br>
      `,
    },
    {
      id: 7,
      header: "COLLECTION OF INFORMATION",
      body: `
            • Our Platform has reasonable security measures and safeguards in place to protect Your privacy and Personal Information from loss, misuse, unauthorized access, disclosure, destruction, and alteration of the information in compliance with applicable laws. Further, whenever You access Your account on the Platform or any information relating to it, We offer the use of a secure server. We cannot however ensure or warrant the security of any information You transmit to the Company or guarantee that Your Personal Information and/or other Non-Personal Information provided for availing the Services or accessing the Platform will not be accessed, disclosed, altered or destroyed by a breach of any of Our security measures and safeguards. It is further clarified that You have, so long as You access and/or use the Platform (directly or indirectly), the obligation to ensure that You shall at all times, take adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and security of Your data which shall include and not be limited to Your Personal Information.<br><br>
            • When payment information is being transmitted on or through the Platform, it will be protected by encryption technology. You expressly consent to the sharing of Your information with third party service providers, including payment gateways, to process payments and manage your payment related information. We take various steps and measures to protect the security of your information from misuse, loss, unauthorized access, modification or disclosure. We use the latest secure server layers encryption and access control on our systems. Please do not share your Account’s login, password and OTP details with anybody.<br><br>
            • While We observe reasonable security measures to protect Your Personal Information on all our digital platforms, security risks may still arise for reasons outside of our control such as hacking, virus dissemination, force majeure events, breach of firewall etc. Please note that the above mentioned measures do not guarantee absolute protection to the Personal Information. The Company assumes no liability or responsibility for disclosure of Your information due to errors in transmission, unauthorized third-party access, or other causes beyond its control. You play an important role in keeping Your personal information secure. You shall not share Your Personal Information or other security information for Your account with anyone. The Company has undertaken reasonable measures to protect Your rights of privacy with respect to Your usage of the Platform controlled by Us and Our Services. However, We shall not be liable for any unauthorized or unlawful disclosures of Your personal information made by any third parties who are not subject to Our control.<br>
            `,
    },
    {
      id: 8,
      header: "INTELLECTUAL PROPERTY POLICY",
      body: `
    • All of the content on the Platform, including, without limitation, all of the page headers, images, illustrations, graphics, audio clips, video clips or text, reports generated, trademarks, trade names (“Platform Content“), constitute our and our licensors’ intellectual property. Copyright laws in all applicable jurisdictions protect the Platform and the Platform Content.<br>
    • You may access the Platform, avail of the features and facilities and utilize the Platform Content in connection with the subject matter thereof. You are not entitled to duplicate, distribute, create derivative works of, display, or commercially exploit the Platform Content, features or facilities, directly or indirectly, without our prior written permission. If you would like to request permission to commercially exploit any particular Platform Content, you could contact us in the manner provided for herein.<br>
    • BULWARK CAPITAL and its licensors, if any, are the sole owners of the underlying software and source code associated with the Platform and all the trademarks, copyright and any other intellectual property rights of any nature in the Platform.<br>
    `,
    },
    {
      id: 9,
      header: "USER ACCOUNT, PASSCODE & SECURITY",
      body: `
      • You will have an option to authorize important actions via one-time-password (OTP) or security passcode on the Platform. You are responsible for maintaining the confidentiality of the one-time-password (OTP) or security passcode and your account (“Account”) and you are also fully responsible for all activities that occur using your Account. You agree to: (a) immediately notify us of any unauthorized use of your OTP, passcode or Account or any other breach of security; and (b) ensure that you exit from your Account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to comply with this Clause.<br>
      • You hereby acknowledge that the only way to de-register your account or delete your records with us is by sending a formal request. To that effect, BULWARK CAPITAL shall be subject to requirements under applicable law to remove and/delete all such information.<br>
      `,
    },
    {
      id: 10,
      header: "USE OF PLATFORM – FEATURES AND FACILITIES",
      body: `
    • Through the Platform, BULWARK CAPITAL assists Users to gain access to certain credit facilities.<br>
    • BULWARK CAPITAL hereby grants to you a restricted, non-transferable, license to access your account via the Platform’s web & mobile app interface, which you own or control, to avail of the functions and features in accordance with these T&Cs.<br>
    • Your usage of the Platform will require you to provide to us certain personal and financial information, which shall be collected and maintained as per the terms laid down in our privacy policy available at Privacy Policy.<br>
    • We shall not mediate or attempt to get involved in and resolve any disputes or disagreements between you and third party/ies.<br>
    • We will use your name as per the PAN records in NSDL as the name in the application.<br>
    • BULWARK CAPITAL may update the Platform from time to time in order to, inter alia, increase its efficiency, optimize user interface and add new features and/or facilities.<br>
    • We allow our affiliates or third parties to collect certain information when you visit our Platform through the use of cookies or third-party web beacons.<br>
    • You are required to seek BULWARK CAPITAL’s permission in case you are writing something associated with the BULWARK CAPITAL on social media or otherwise. In the event you post any content on your own site or any other third party sites about us without our prior written permission, we may take strict actions against you.<br>
    • You may choose to, or we may invite you to submit comments or ideas about the Services rendered under these T&Cs, including without limitation about how to improve the Services. By submitting any such ideas, you agree that your disclosure is gratuitous, unsolicited and without restriction and will not place BULWARK CAPITAL or any of its affiliates or subsidiaries, under any fiduciary or other obligation, and that BULWARK CAPITAL will be free to use the idea without any additional compensation to you, and/or to disclose the idea on a non-confidential basis or otherwise to anyone. You further acknowledge that, by acceptance of your submission, BULWARK CAPITAL does not waive any rights to use similar or related ideas previously known to, or developed by employees, or obtained from sources other than you.<br>
    • In the event you have any complaint against the contents (or any portion thereof), including that the contents therein are incorrect or breach the rights of a third party, BULWARK CAPITAL shall take all commercially reasonable measures to address the same. However, BULWARK CAPITAL’s sole obligation in this regard shall be to remove such content from the Platform; and the user shall have no further cause of action against BULWARK CAPITAL.<br>
    `,
    },
    {
      id: 11,
      header: "SERVICE PROVIDERS",
      body: `
      • We may employ third-party service providers and individuals due to the following reasons:<br>
          ◦ To facilitate our Service;<br>
          ◦ To provide the Service on our behalf;<br>
          ◦ To perform Service-related services; or<br>
          ◦ To assist us in analyzing how our Service is used.<br>
      • You acknowledge that the third-party service provider/agent/agencies will have access to your personal information on a need basis to assist us in rendering Service, and the service providers are restricted from using the same for any other reason. The third-party service providers are obligated not to disclose or use the information for any other purpose.<br>
      `,
    },
    {
      id: 12,
      header: "YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN INDIA",
      body: `
    • These T&Cs are intended for Users within the territory of India and govern your rights as per applicable law within the territory of India. However, in the event you fall under a jurisdiction outside the purview of Indian law, we will not be liable for any claim, action and/or right initiated/exercised by you as per the extant laws of that jurisdiction. Therefore, we request you to kindly use the Platform accordingly.
    `,
    },
    {
        id: 13,
        header: "CUSTOMER COMMUNICATIONS",
        body: ` • Accepting these T&Cs implies your express consent to be contacted by us, our agents, representatives, affiliates, or anyone calling on our behalf at any contact number, or physical or electronic address provided by you while registering your Account. You further agree to us contacting you in any manner, including without limitation, SMS messages (including text messages), WhatsApp, calls using pre-recorded messages or artificial voice, calls and messages delivered using auto telephone dialing system or an automatic texting system, and notifications sent via web or mobile app. Automated messages may be played when the telephone is answered, whether by you or someone else. In the event that an agent or representative calls, he or she may also leave a message on your answering machine, voice mail, or send one via SMS.<br>
    • You certify, warrant and represent that the telephone numbers and/or email addresses and any other information that you have provided to us are your own and not someone else’s and are true, accurate, current and complete. You represent that you are permitted to receive calls at each of the telephone numbers you have provided to us and emails at each of the email addresses you have provided us. You agree to notify us whenever you stop using a particular telephone number(s) and/or email address(es).<br>
    • You agree and confirm that applicable laws in relation to the unsolicited communication referred in “National Do Not Call Registry” (the “NDNC Registry”) of the Telecom Regulatory Authority of India will not be applicable for such communication/calls/SMS/push notifications/messages through WhatsApp messenger application received from BULWARK CAPITAL, its employees, agents and/or associates.<br>
    • If you would not like to receive any promotional messages or communications, please contact us at support@bulwarkcapital.in to let us know your preferences.<br>
    `,
      },
      {
        id: 14,
        header: "USER GUIDELINES",
        body: ` • In consideration of BULWARK CAPITAL granting you the rights hereunder, you hereby agree not to use the Platform for any purpose that is unlawful under any applicable laws and/or in violation of the terms of these T&Cs and our Privacy Policy. You shall not use the Platform in any manner that could damage, disable, overburden, or impair our server, or any network(s) connected to any server, or interfere with any other party’s use and enjoyment of the Platform. You shall not attempt to gain unauthorized access to any functions and features, other user accounts, computer systems or networks connected to any server, in any manner, including, through hacking, password mining or any other means. You shall not obtain or attempt to obtain any materials or information through any means which is not intentionally made available through the Platform.<br>
    • The Platform is made available to you for your own personal and non-commercial use alone. You shall not allow third parties to: (i) make and/or distribute copies of the Platform or any deliverable generated by the Platform; (ii) attempt to copy, reproduce, alter, modify and/or reverse engineer the Platform; and/or (iii) create derivative works of the Platform.<br>
    • You accept that any and all operations emanating from your Device shall be assumed to have been initiated by you.<br>
    • You shall not copy, reproduce, distribute, or create derivative works of our content that is available on the Platform. Also, you shall not reverse engineer or reverse compile our technology that is available on the Platform.<br>
    • You shall request BULWARK CAPITAL, to block the Account and change the passcode/password immediately for the Account, if your Device has been lost or stolen.<br>
    • You are responsible for any and all activities that occur in your Account. You agree to notify BULWARK CAPITAL immediately of any unauthorized use of the Account or any other breach of security. BULWARK CAPITAL shall not be liable for any loss to you or your organization owing to negligent actions or a failure on your part to inform BULWARK CAPITAL, within a reasonable time, about loss or theft of your Device and/or any unauthorized access in your Account, either with or without your knowledge.<br>
    • You shall be liable for losses incurred by BULWARK CAPITAL or any other party due to a third party’s use of the Account. You shall not use any other person’s account at any time, without the permission of the account holder and BULWARK CAPITAL.<br>
    • BULWARK CAPITAL shall make all reasonable efforts to ensure that your information is kept confidential. However, BULWARK CAPITAL shall not be responsible for any disclosure or leakage of confidential information and/or loss or damage of the Device due to theft, negligence or failure on your part to practice safe computing.<br>
    • You shall ensure that while using the functions and features, all prevailing and applicable laws, rules and regulations, shall at all times, be strictly complied with by you and BULWARK CAPITAL shall not be liable in any manner whatsoever for default of any nature, by you, regarding the same.<br>
    • You understand and acknowledge that upon using the Platform, you authorize us to access third party sites designated by you, on your behalf, to retrieve such information requested by you, and to register for accounts requested by you. For all purposes hereof, you hereby grant us a limited powers and hereby authorize us with the full power of substitution and resubstitution, for you and in your name, place and stead, in any and all capacities, to access third party internet sites, servers or documents, retrieve information, and use your information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with Services and facilities available on the Platform, as fully to all intents and purposes as you might or could do in person. You acknowledge and agree that when we access and retrieve information from third party sites, we are acting as your agent, and not the agent or on behalf of the third party. You agree that such third parties shall be entitled to rely on the foregoing authorization, agency granted by you.<br>
    • You hereby acknowledge and agree that BULWARK CAPITAL shall not be liable for any delay or failure of any transaction undertaken by you using our Services.<br>
    • You hereby agree and express your voluntary, unequivocal and informed consent to your personal data, investment details or financial information (including such data as may be designated as ‘personally identifiable data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 or other applicable law), being processed, disclosed and/or transferred by BULWARK CAPITAL to third-party entities.<br>
    `,
      },
      {
        id: 15,
        header: "INDEMNIFICATION",
        body: `
        • You agree to protect, defend and indemnify us and hold us and our representatives harmless from and against any and all claims, damages, costs and expenses, including attorneys’ fees, arising from or related to your access and use of the Platform in violation of these T&Cs and/or your infringement, or infringement by any other user of your Account, of any intellectual property or other right of anyone.<br>
        • The terms of this provision will survive any termination or cancellation of these T&Cs or your use of the Platform.<br>
        `,
      },
      {
        id: 16,
        header: "SUPPORT",
        body: `
        • Basic support for the Services shall be provided by BULWARK CAPITAL. BULWARK CAPITAL shall also use commercially reasonable efforts to make the Services available 24 hours a day, 7 days a week, except for (i) planned downtime (of which BULWARK CAPITAL shall give at least 4 hours’ notice); or (ii) any unavailability due to circumstances beyond BULWARK CAPITAL’s reasonable control.
        `,
      },
      {
        id: 17,
        header: "WARRANTIES",
        body: `
    • The Platform and the functions and features therein are provided on an “as is” and on an “as available” basis. We expressly disclaim all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.<br>
    • We shall make reasonable efforts to make available the Platform and the functions and features at all times. However, we make no warranty that the Platform shall meet your requirements, be uninterrupted, timely, secure, and/or error free. Further we do not make any warranty as to the results that may be obtained from the use of the functions and features or as to the accuracy, reliability and/or quality of the output derived therefrom.<br>
    • We shall not be liable for the loss and/or damage of the confidential information or data of the User arising as a result of an event or a series of related events, that is beyond the control of BULWARK CAPITAL, including failures of or problems with the internet or part of the internet, attempted hacker attacks, hacker attacks, denial of service attacks and/or viruses or other malicious software attacks or infections.<br>
    • You are advised to keep your username, password, one-time-password (OTP) and passcode safe. We shall not be liable in the event your Account is hacked due to errors or omissions at your end.<br>
    • Any material downloaded or otherwise obtained through the Platform is done at your own discretion and risk and you are solely responsible for any damage to your Device or loss of data that results from the download of any such material. No advice or information, whether oral or written, obtained by you from us through or from the Service will create any warranty not expressly stated in these T&Cs.<br>
    • We shall not be liable for any losses and/or damages which may arise as a result of a third party entity’s usage of the User’s personal data or financial information (including such data as may be designated as ‘personally identifiable data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011).<br>
    • BULWARK CAPITAL shall not be liable for any failure or delay in performing its obligations under this facility if such failure or delay (i) is directly caused by the Partner(s) acts or omission; (ii) results from actions undertaken by BULWARK CAPITAL in a reasonable good faith to comply with applicable law or to prevent fraud; or (iii) is caused by events beyond BULWARK CAPITAL’S control, including but not limited to vandalism, hacking, theft, phone service disruptions, internet disruptions, loss of data, extreme or severe weather conditions or any other causes in the nature of force majeure event.<br>
    `,
      },
      {
        id: 18,
        header: "LINK TO OTHER WEBSITES",
        body: `
        • The links to any third party websites on the Platform are provided solely as pointers in connection with the Services, and BULWARK CAPITAL has no control over the content on such third party websites. BULWARK CAPITAL makes no warranties concerning the content of such websites, including the accuracy, completeness, reliability of said websites, nor does BULWARK CAPITAL warrant that such website or content is free from any claims of copyright, trademark or other infringement of the rights of third parties or that such site or content is devoid of viruses or other contamination. If you choose to follow a link to a third party website, you will do so at your own risk. You also acknowledge that links to third party websites do not imply any endorsement of or responsibility for the opinions, ideas, products, information or Services offered at such sites, or any representation regarding the content at such third party websites.
        `,
      },
      {
        id: 19,
        header: "CLOSURE OF ACCOUNTS",
        body: `
        • In the event you do not log in to the Platform or use the Services for two (2) or more years, BULWARK CAPITAL may close your Account. However, BULWARK CAPITAL will retain your information in accordance with these T&Cs and our Privacy Policy.
        `,
      },
      {
        id: 20,
        header: "LIMITATION OF LIABILITY",
        body: `
        • In no event shall we be liable for any direct, indirect, punitive, incidental, special or consequential damages or for any damages whatsoever including, without limitation, damages for loss due to use, data or profits, arising out of or in any way connected with the access, use or performance of this Platform’s functions and features or for interruptions, delay, etc., even if we were advised of the possibility of damages resulting from the cost of getting substitute facilities on the Platform, any products, data, information or services purchased or obtained or messages received or transactions entered into through or from the Platform, unauthorized access to or alteration of your transmissions or data statements or conduct of anyone on the Platform, or inability to use the Platform, the provision of or failure to provide the functions and features, whether based on contract, tort, negligence, strict liability or otherwise. this clause shall survive in perpetuity
        `,
      },
      {
        id: 21,
        header: "SEVERABILITY",
        body: `
        • If any provision of these T&Cs is held to be illegal, invalid or unenforceable under any present or future applicable laws: (a) such provision will be replaced with another, which is not prohibited or unenforceable and has, as far as possible, the same legal and commercial effect as that which it replaces; and (b) the remaining provisions of the T&Cs will remain in full force and effect and will not be affected by the illegal, invalid, or unenforceable provision or by its severance here from.
        `,
      },
      {
        id: 22,
        header: "TERMINATION",
        body: `
    • These T&Cs and the right to use granted hereunder shall take effect on the date you create an Account with the Platform, and shall continue in effect until termination of these T&Cs in accordance with this section.<br>
    • We may suspend/terminate the use of the Platform at any time, with or without cause, after giving notice of the suspension/termination to you.<br>
    • Upon termination, the rights and license granted to you herein shall terminate and you must cease all use of the Platform.<br>
    `,
      },
      {
        id: 23,
        header: "WAIVER",
        body: `
        •Any failure on the part of BULWARK CAPITAL to require performance of any provision of these T&Cs shall not affect its right to full performance thereof at any time thereafter, and any waiver by BULWARK CAPITAL of a breach of any provision hereof shall not constitute a waiver of a similar breach in the future or of any other breach.
        `,
      },
      {
        id: 24,
        header: "ASSIGNMENT",
        body: `
        • You shall not assign or transfer any rights, obligations, or privileges that you have under these T&Cs, without the prior written consent of BULWARK CAPITAL. BULWARK CAPITAL reserves the right to assign its rights, obligations or privileges to third parties and you hereby agree to such assignment. Any assignment or transfer in violation of this clause will be deemed null and void.
        `,
      },
      {
        id: 25,
        header: "INDEPENDENCE FROM PLATFORMS",
        body: `
        • The Platform is independent of any other platform on which it is located. The Platform is not associated, affiliated, sponsored, endorsed or in any way linked to any platform operator, including, without limitation, Apple, Google, Android or (each being an “Operator”).<br>
        • Your access to or use of the Platform is also bound by the terms and conditions of the Operator.<br>
        • You and BULWARK CAPITAL acknowledge that these T&Cs are concluded only between you and BULWARK CAPITAL, and not with an Operator, and BULWARK CAPITAL, not those Operators, are solely responsible for the Platform and the content thereof to the extent specified in these T&Cs.<br>
        • BULWARK CAPITAL is solely responsible for providing any maintenance and support Services with respect to the Platform as required under applicable law. You and BULWARK CAPITAL acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and support Services with respect to the Platform.<br>
        • You and BULWARK CAPITAL acknowledge that BULWARK CAPITAL, not the relevant Operator, is responsible for addressing any User claims or any third party claim relating to the Platform or your access and/or use of the Platform, including, but not limited to: (i) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (ii) claims arising under consumer protection or similar legislation.<br>
        • You and BULWARK CAPITAL agree that, in the event of any third party claim that the Platform or your possession and use of the Platform infringes that third party’s intellectual property rights, BULWARK CAPITAL and not the relevant Operator, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim; provided such infringement was caused by BULWARK CAPITAL.<br>
        • You must comply with any applicable third party terms of agreement when using the Platform (e.g. you must ensure that your use of the Platform is not in violation of your Device agreement or any wireless data service agreement).<br>
        • You and BULWARK CAPITAL agree that the relevant Operator, and that Operator’s subsidiaries, are third party beneficiaries of these T&Cs, and that, upon your acceptance of these T&Cs, that Operator will have the right (and will be deemed to have accepted the right) to enforce these T&Cs against you as a third party beneficiary thereof.<br>
        `,
      },
      {
        id: 26,
        header: "UPDATES",
        body: `
        • BULWARK CAPITAL reserves the right to update the Platform, in order to, inter alia, increase efficiency, optimize user interface, and add new facilities from time to time. Update packages will be sent to you on your Device for download and installation.<br>
        • You hereby agree to install the updates from time to time and acknowledge that BULWARK CAPITAL will only be able to provide Account support for the Platform if you ensure installation of all updates upon receiving notifications thereof when using the Platform.<br>
        `,
      },
      {
        id: 27,
        header: "VALIDITY OF T&Cs",
        body: `
        • These T&Cs shall apply when you complete the authentication process and create an Account and shall remain valid and binding on you for so long as you maintain the Account and avail Services.<br>
        `,
      },
      {
        id: 28,
        header: "SECURITY",
        body: `
        • You are prohibited from violating or attempting to violate the security of the Platform, including, without limitation:<br>
          ◦ accessing data not intended for you or logging into an account which you are not authorized to access;<br>
          ◦ attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or<br>
          ◦ interfering with Service to any User, host, or network.<br>
        • You shall not misuse this Platform by intentionally introducing viruses, trojans, worms, logic bombs or other materials that are malicious or technologically harmful (together “Viruses“). You will not attempt to gain unauthorized access to the Platform, or any server, computer or database connected to the Platform. You will not attack this Platform via a denial-of-service attack. Further, you shall not interfere with or circumvent any security feature of the Platform or any feature that restricts or enforces limitations on use of or access to the Platform, such as probing or scanning the vulnerability of any system, network or breach.<br>
        • Should you breach the provisions of this Clause, you will be liable to be prosecuted under the Information Technology Act, 2000 and other applicable statutes. We will immediately report such breach to the relevant law enforcement authorities and will co-operate with such authorities by disclosing your identity to them. In the event of such a breach, your rights to use this Platform will cease immediately.<br>
        • We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that may infect your computer equipment, computer programs, data or other proprietary material due to your use of this Platform or to your downloading of any material posted on it.<br>
        • You agree to immediately report to us all incidents involving suspected or actual unauthorized access, disclosure, alteration, loss, damage, or destruction of data.<br>
        • You shall not interfere with or disrupt (or attempt to interfere with or disrupt) access and enjoyment of the Platform by other users or any host or network, including, without limitation, creating or transmitting unwanted electronic communications such as “spam” to other users, overloading, flooding or mail-bombing the Platform, or scripting the creation of content in such a manner as to interfere with or create an undue burden on the Platform.<br>
        • All actions performed by any person using your account and password shall be deemed to have been committed by you and you shall be liable for the same. We reserve the right to suspend/terminate your Account at any time if it is found that you have been sharing the password with any unauthorized user.<br>
        `,
      },
      {
        id: 29,
        header: "MONITORING",
        body: `
        • All electronic communications and content presented and/or passed to us, including that presented and/or passed from remote access connections, may be monitored, examined, saved, read, transcribed, stored, or retransmitted in the course of daily operations by any duly authorized employee or agent of ours in the exercise of their duties, or by law enforcement authorities who may be assisting us in investigating possible contravention/non-compliance with applicable law. Electronic communications and content may be examined by automated means. Further, we have the right to reject, at our sole discretion, from the Platform any electronic communications or content deemed not to be in compliance with the corporate policies and procedures of BULWARK CAPITAL.<br>
        `,
      },
      {
        id: 30,
        header: "GOVERNING LAW AND JURISDICTION",
        body: `
        • These T&Cs (and by extension, the Privacy Policy) are governed and construed in accordance with Indian law. By using the Platform, you hereby irrevocably consent to the exclusive jurisdiction and venue of courts in Bangalore, Karnataka, India, in the event of any disputes arising out of or in relation to your access to and use of the Platform.<br>
        `,
      },
      {
        id: 31,
        header: "GRIEVANCE REDRESSAL MECHANISM",
        body: `
    • BULWARK CAPITAL aims to provide the best customer service through its Platform. We value customer-relationship and strive to offer an efficient and robust grievance redressal mechanism.<br>
    • If you have any questions or concerns regarding the Platform, please feel free to contact us.<br>
    • <strong>Customer Service Team (Level 1):</strong> You can reach us for any query/complaint by writing to us at support@bulwarkcapital.in.<br>
    • <strong>Grievance Team (Level 2):</strong> If the Complaint/Grievance is not redressed by the Customer Service Centre within 7 working days, the customer shall approach the Digital Lending Grievance Redressal Officer (GRO) of the Company at below details:<br>
      ◦ <strong>Name:</strong> DINESH SHARMA<br>
      ◦ <strong>Email:</strong> dinesh@bulwarkcapital.in<br>
    `,
      },
     
    
     
  ];

  const formatBody = (body) => {
    return <div dangerouslySetInnerHTML={{ __html: body }} className="mt-2" />;
  };
  return (
    <div>
      <Header />
      <section className="section-padding-two padding-top">
        <h5 className="text-center mb-2">Terms & Conditions</h5>
        <p>These terms and conditions contain the terms on which You(User) access and register on the BULWARK CAPITAL’s Web & Mobile technology platform (Platform), owned and managed by BULWARK CAPITAL PRIVATE LIMITED to avail various loan facilities offered by our NBFC through the Platform (“Credit Facilitation Services / CFS").
Please read these terms of use carefully. By accessing the Platform or using this service, you signify that you have read, understood and agree to be bound by these T&Cs, Privacy Policy and any other applicable law, whether or not you are a registered member of the Platform. If you do not agree with these terms, please do not access the Platform or use this service.
For the purposes of these T&Cs, “we”, “our” and “us” shall mean BULWARK CAPITAL PRIVATE LIMITED, and/or third-party service providers engaged by BULWARK CAPITAL to render certain Services on the Platform and ‘you’ and ‘your’ shall mean a User who meets the eligibility criteria set out below.</p>
        <Accordion defaultActiveKey="0">
          {data.map((item) => (
            <Accordion.Item eventKey={item.id} key={item.id}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body style={{ padding: "10px 20px" }}>
                {formatBody(item.body)}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
      <Footer />
    </div>
  );
};

export default TermsCondition;

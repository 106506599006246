import React from "react";


const AboutHome = () => {
  const dataTwo=[
    {
      id:1,
      data:"Fetch Portfolio",
     img:"/assets/images/Fetch Portfolio.png"
    },
    {
      id:2,
      data:"KYC(PAN, Aadhar & Bank Verification)",
     img:"/assets/images/KYC.png"
    },
    {
      id:3,
      data:"Pledge",
     img:"/assets/images/Pledge.png"
    },
    {
      id:4,
      data:"Sign eAgreement",
        img:"/assets/images/loan.png"
    },
    {
      id:5,
      data:"Activate E NACH",
   
      img:"/assets/images/Sign eAgreement.png"
    }
    
  ]
  return (
    <>
      <div className="about-home-wrapper primary-color section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img-about-container">
                <img src="/assets/images/business.png" alt="dataOne" className="about_img" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-section-wrapper">
                <h2 className="secondary-color-text mx-4">Instant Loan in 5 Steps</h2>
                <div className="vertical-steps-list mt-3">
                  {
                    dataTwo.map((item)=>(
                      <div key={item.id}>
                            <div className="instant-wrapper" >
                              <div>
                                <img src={item.img} alt="current-data"/>
                              </div>
                              <div>
                                <h6>{item.data}</h6>
                              </div>
                             
                            </div>
                      </div>
                    ))
                  }
                  <div className="d-flex" style={{gap:"20px;"}}>
                  <h4 className="mt-3 fw-bold secondary-color-text">Congrats! your loan is disbursed  </h4>
                  <img src="/assets/images/fgrg.png" alt="dataOne" className="data-load" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHome;

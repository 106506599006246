import React from 'react'
import Banner from '../components/Banner'
import AboutHome from '../components/AboutHome'
import MidSection from '../components/MidSection'
import AccordianSection from '../components/AccordianSection'
import Header from "../components/Header";
import Footer from "../components/Footer";
import OfferingTwo from '../components/OfferingTwo'

const Home = () => {
  return (
    <>
      <Header/>
      <Banner/>
      <AboutHome/>
      <OfferingTwo/>
      <MidSection/>
      <AccordianSection/>
      <Footer/>
    </>
  )
}

export default Home

import React from 'react'

const SpinnerData = ({message}) => {
  return (
    <div>
      <div className="loading">Loading&#8230;</div>
      <div className="content">
        <h3>stuff goes in here!</h3>
      </div>
      {message && <h6 className="spinner-message">{message}</h6>}
    </div>
    
  )
}

export default SpinnerData

import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import Header from "../Header";
import Footer from "../Footer";
import Steps from '../StepsPage/Steps';

const videoConstraints = {
  width: 200,
  height: 200,
  facingMode: "user",
};

const WebCam = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const navigate = useNavigate();  // Correctly placed inside the component

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setIsCameraOn(false); // Turn off the camera after capturing the image
  }, [webcamRef, setImgSrc]);

  const retake = () => {
    setImgSrc(null);
    setIsCameraOn(true); // Turn on the camera for retaking the image
  };

  const handleSubmitData = () => {
    navigate("/portfolio"); // Navigate to the desired route on submit
  };

  return (
    <>
      <Header />
      <div className="section-padding ">
        <Steps isKyc={true} />
        {isCameraOn ? (
          <div className="mx-auto text-center">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              minScreenshotWidth={180}
              minScreenshotHeight={180}
            />
          </div>
        ) : (
          <div className="text-center m-auto">
            {imgSrc && <img src={imgSrc} alt="img" className="current-img" />}
          </div>
        )}
        <button onClick={isCameraOn ? capture : retake} className="button-content mt-3 mx-auto">
          {isCameraOn ? "Capture Photo" : "Retake Photo"}
        </button>
        <button className="button-content mt-3 mx-auto" onClick={handleSubmitData}>Submit</button>
      </div>
      <Footer />
    </>
  );
};

export default WebCam;

import React from "react";

const MidSection = () => {
  return (
    <>
      <div className="midsection section-padding">
        <div className="midsection-wrapper">
          <div>
            <h4 className="text-white">
              Receive instant expert guidance at your fingertips.
            </h4>
            <p className="text-white">
              We are available Monday To Saturday from 10AM to 5PM
            </p>
          </div>
          <div>
            <button className="chat-content mx-auto">Chat With Us</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidSection;



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
// import "../public/assets/css/style.css";
import  Router  from "../src/route/Router";
function App() {
  return (
   <><Router/></>
  );
}

export default App;

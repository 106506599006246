import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SpinnerData from "../SpinnerData";
import NumberPatternDisplay from "../../pages/NumberPatternDisplay";
import { ToastContainer, toast } from "react-toastify";
import api from "../../axios/api";
import { useDispatch, useSelector } from "react-redux";
import { storeData } from "../../store/dataslice";

const PenOtp = ({ setPenDetail, setOtpShow, phoneNumber }) => {
  const [otp, setOtp] = useState("");
  const [otperror, setOtpError] = useState("");
  const [loadingdata, setLoadingData] = useState(false);
  const [resendValue, setReSend] = useState(false);
  const dataR = useSelector((state) => state.data);
  const [timer, setTimer] = useState(30);
  const dispatch = useDispatch();
  // Function to handle OTP input changes
  const handleotp = (e) => {
    const newOtpNumber = e.target.value;
    let numericValue = newOtpNumber.replace(/\D/g, "");
    numericValue = numericValue.slice(0, 6);
    setOtp(numericValue);
    if (numericValue.length !== 6) {
      setOtpError("Only 6 digit OTP allowed");
    } else {
      setOtpError("");
    }
  };

  // Function to handle backspace key press in OTP input
  const handleOtpBackspace = (e) => {
    if (e.keyCode === 8 && otperror) {
      setOtpError("");
    }
  };

  // Effect to handle the countdown timer
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  // Function to reset timer and resend OTP
  const resetTimer = async () => {
    setTimer(30);
    try {
      const response = await api.post(`gdssdgfdgdfgfdgs`, {
        phone: phoneNumber,
        type: "login-otp",
      });
      if (response.status === 200) {
        setReSend(true);
        toast.success("OTP Resent successfully");
      } else {
        console.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
    setTimeout(() => {
      setReSend(false);
    }, 30000);
  };

  // Function to handle form submission
  const handlesubmit = async (e) => {
    e.preventDefault();
    const otpNumber = otp.trim();

    try {
      if (!otpNumber) {
        toast.error("Please fill the OTP number");
        return;
      }
      setLoadingData(true);

      const dataShare = {
        accessToken: dataR.accessToken,
        clientRefNo: dataR.clientRefNo,
        otp: otpNumber,
        otpRef: dataR.otpRef,
        reqId: dataR.reqId,
        userSubjectReference: dataR.userSubjectReference,
      };
      console.log("Sending data to server:", dataShare);
      const response = await api.post(`verify-otp-user`, dataShare);
      console.log("response otp ", response);
      dispatch(storeData(response));
      if (response.status === 200) {
        setPenDetail(true);
        setOtpShow(false);
        toast.success("Verify OTP successfully");
      }
    } catch (error) {
      console.log("error occurred in verify-otp", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <div>
      <ToastContainer autoClose={500} className="data-toast" />
     
      {loadingdata && <SpinnerData message="Please Wait  While We Fetch Your Portfolio" />}
      <div className="card-pen-wrapper">
      
        <h4 className="text-center">OTP Verification</h4>
        <p className="text-center">
          Enter OTP Sent to{" "}
          <NumberPatternDisplay latesPhoneNumber={phoneNumber} />
        </p>
        <form>
          <div className="form-group form-wrapper-login">
            <input
              type="text"
              className="form-control"
              value={otp}
              placeholder="Enter Your OTP Number"
              onChange={handleotp}
              onKeyDown={handleOtpBackspace}
            />
            {otperror.length > 0 && (
              <span className="error-text">{otperror}</span>
            )}
          </div>
          <div className="data-content-otp">
            <p className="font-size-data">
              Expires in 00:{timer < 10 ? `0${timer}` : timer} Minutes
            </p>
            <NavLink
              className="text-content"
              onClick={resendValue ? null : resetTimer}
              aria-disabled={resendValue ? true : undefined}
            >
              Resend OTP
            </NavLink>
          </div>
          <button className="button-content w-100 mt-2" onClick={handlesubmit}>
            Submit OTP
          </button>
          <p className="mt-3 text-center font-size-data">
               
          By Proceeding I accept<span><NavLink to="/terms" className="secondary-color-text" target="_blank"> T&C,</NavLink></span> and the<span>
            <NavLink to="/privacy" className="secondary-color-text" target="_blank"> Privacy Policy </NavLink>
            </span>
            
            and Authorize to obtain my KYC &
            Bureau Information.
          </p>
        </form>
      </div>
    </div>
  );
};

export default PenOtp;

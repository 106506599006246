import React from 'react'
import Steps from '../StepsPage/Steps'

const PledgePage = () => {
  return (
    <div>
      <Steps isPledge={true}/>
    </div>
  )
}

export default PledgePage

import React from "react";

const History = () => {
  return (
    <section className="section-padding history-wrapper">
      <div className="continer">
        <div className="col-lg-12">
          {/* <span className="secondary-color-text text-center d-block">
            Our History
          </span> */}
          <h3 className="text-center">Our Journey through time</h3>
          <div className="history-wrapper">
            <div className="img-history text-center my-5">
              <img src="/assets/images/GroupImg.png" alt="history-data" />
            </div>
            <div className="history-wrapper-heading">
              <h1>2017</h1>
            </div>
            <div className="heading-wrapper-content">
              <span className="secondary-color-text">
                Founding Vision and Securing the RBI License
              </span>
              <p>
                Bulwark Capital was founded with a clear vision to revolutionize
                the financial services landscape in India by offering innovative
                and customer-centric financial products. Recognizing the
                potential of India's burgeoning financial market, the company
                sought to formalize its operations and establish itself as a
                trusted financial institution. In 2017, after rigorous
                preparation and meeting all regulatory requirements, Bulwark
                Capital was granted a Non-Banking Financial Company (NBFC)
                license by the Reserve Bank of India (RBI). This milestone
                allowed the company to operate under RBI’s regulatory framework,
                ensuring transparency, credibility, and trust for its clients.
              </p>
            </div>
            <div className="history-wrapper-heading-two">
              <h1>2018</h1>
            </div>
            <div className="heading-wrapper-content-two">
              <span className="secondary-color-text text-right">
                Launch of Loan Against Property
              </span>
              <p>
                With the RBI license secured, Bulwark Capital quickly moved to
                expand its product offerings. In 2018, the company launched its
                first major financial product: loans against property (LAP).
                This service allowed property owners to leverage the value of
                their real estate to access substantial funds for personal or
                business needs. By offering competitive interest rates and
                flexible repayment options, Bulwark Capital positioned itself as
                a reliable partner for individuals and businesses looking to
                unlock the value of their property assets.
              </p>
            </div>
            <div className="history-wrapper-heading-three">
              <h1>2020</h1>
            </div>
            <div className="heading-wrapper-content-three">
              <span className="secondary-color-text">
                Entry into Small-Ticket Finance for Individuals
              </span>
              <p>
                Recognizing the growing demand for more accessible and
                smaller-sized financial products, Bulwark Capital diversified
                its offerings in 2020 by entering the small-ticket finance
                segment. This move was aimed at catering to the needs of
                individuals who required quick, short-term financing for
                personal expenses, such as medical emergencies, education, or
                small business ventures. By leveraging technology and
                data-driven credit assessments, Bulwark Capital was able to
                offer these loans with minimal paperwork and swift disbursement,
                thereby reaching a wider audience across different income
                segments
              </p>
            </div>
            <div className="history-wrapper-heading-four">
              <h1>2022</h1>
            </div>
            <div className="heading-wrapper-content-four">
              <span className="secondary-color-text text-right">
                Introduction of Loans for Small Business Owners{" "}
              </span>
              <p>
                Recognizing the critical role of small businesses in the Indian
                economy, Bulwark Capital further diversified its offerings in
                2022 by introducing loans tailored specifically for small
                business owners. This product was designed to provide accessible
                financing options to entrepreneurs and small enterprises,
                helping them to manage cash flow, expand operations, purchase
                inventory, or invest in new opportunities. By offering
                customized loan solutions with quick disbursement and reasonable
                interest rates, Bulwark Capital aimed to support the growth and
                sustainability of small businesses across the country.
              </p>
            </div>
            <div className="history-wrapper-heading-five">
              <h1>2024</h1>
            </div>
            <div className="heading-wrapper-content-five">
              <span className="secondary-color-text">
                Expansion into Loan Against Mutual Funds
              </span>
              <p>
                Continuing its trajectory of innovation and customer focus,
                Bulwark Capital expanded its product portfolio in 2024 by
                introducing loans against mutual funds. This service allowed
                investors to access liquidity without having to sell their
                mutual fund holdings, thus providing a flexible and efficient
                way to meet financial needs while still benefiting from
                potential market appreciation. By offering instant loans with
                approval in under 5 minutes, Bulwark Capital tapped into the
                growing demand for quick and hassle-free financing solutions for
                savvy investors.{" "}
              </p>
            </div>
            <div className="history-wrapper-heading-six">
              <h1>Future</h1>
            </div>
            <div className="heading-wrapper-content-six">
              <span className="secondary-color-text text-right">
                Vision for the Future
              </span>
              <p>
                From securing an RBI license in 2017 to launching diverse
                financial products over the years, Bulwark Capital has
                consistently demonstrated its commitment to innovation, customer
                satisfaction, and financial inclusion. As it continues to
                evolve, the company remains focused on expanding its product
                offerings and enhancing its digital platforms to better serve
                its clients. With a strong foundation and a clear vision,
                Bulwark Capital aims to solidify its position as a leading
                player in the Indian financial services industry, empowering
                individuals and businesses to achieve their financial goals with
                confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;

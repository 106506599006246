import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import api from "../axios/api";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/slice";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();
  const authToken = useSelector((state) => state.token.token);
  const [names, setName] = useState('');

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const fetchData = async () => {
    try {
      const response = await api.get("profile", authToken);
      if (response.status === 200) {
        setName(response?.data?.data?.name);
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const handleLogOut = () => {
    dispatch(logout()); 
    navigate("/"); 
  };

  return (
    <>
      <nav className="nav-wrapper">
        <SubHeader />
        <div className="nav-main-wrapper">
          <div className="header-left">
            <div className="main-logo">
              <NavLink to="/">
                <img src="/assets/images/logo.png" alt="current_Data" />
              </NavLink>
            </div>
          </div>
          <div className="header-right">
            <div className="navbar-wrapper">
              <ul>
              {authToken ? (
                  <>
                    <li className="nav-link">
                      <NavLink to="/profile" className={isActive("/profile")}>
                        My Profile
                      </NavLink>
                    </li>
                    <li className="nav-link">
                      <NavLink to="/loan" className={isActive("/loan")}>
                       My Loan's
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-link">
                      <NavLink to="/" className={isActive("/")}>
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-link">
                      <NavLink to="/about" className={isActive("/about")}>
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-link">
                      <NavLink to="/benefit" className={isActive("/benefit")}>
                        Benefits
                      </NavLink>
                    </li>
                  </>
                )}
              
                <li className="nav-link">
                  <NavLink to="/faq" className={isActive("/faq")}>
                    Faq's
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="/contact" className={isActive("/contact")}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              {authToken ? (
                <div className="user-detail">
                  <NavLink to="" className="text-white">
                    <i className="fa fa-user"></i>
                  </NavLink>
                  <div className="navlink-user-details">
                    <NavLink to="/profile" className="text-white">
                      {names}
                    </NavLink>
                    {/* <NavLink to="/user-profile" className="text-white">
                      User&nbsp;Profile
                    </NavLink> */}
                    <NavLink to="/" className="text-white" onClick={handleLogOut}>
                      Logout
                    </NavLink>
                  </div>
                </div>
              ) : (
                <div className="button-nav">
                  <button
                    className="button-content w-10"
                    style={{ width: "185px" }}
                    onClick={() => navigate("/login")}
                  >
                    Register/Login
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

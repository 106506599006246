import React from "react";

const OurMisson = () => {
  const data = [
    {
      id: 1,
      para: "Bulwark Capital aims to empower investors by offering flexible and accessible financial solutions through loans against mutual funds. By enabling clients to leverage their existing investments without liquidating them, Bulwark Capital strives to provide liquidity and financial stability, helping clients meet their immediate financial needs while maintaining their long-term investment goals.",
    },
    {
      id: 2,
      para: "Bulwark Capital envisions being a leader in innovative financial services, where clients can seamlessly bridge short-term financial gaps without disrupting their long-term wealth-building strategies. The company aspires to build trust and long-term relationships with its clients by providing transparent, efficient, and customer-centric loan services that enhance financial flexibility and security.",
    },
  ];

  return (
    <section className="section-padding mission-wrapper primary-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img-mission">
              <img
                src="/assets/images/target-setting-hd-8k-wallpaper-stock-photographic-image 1.png"
                alt="run_data"
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-section-wrapper">
              <span className="secondary-color-text ">
                Our Mission & Vision
              </span>
              <h3 className="mt-3">
                Empowering India, Empowering Investors:{" "}
                <span className="secondary-color-text">
                  Where Expertise Meets Accessibility
                </span>
              </h3> 
              {
                data.map((item)=>(
                  <p key={item.id}>{item.para}</p>
                ))
              }
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMisson;

import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import api from "../axios/api";

const AccordianSection = () => {
  const [items, setItems] = useState([]);
  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`faq`);
   
        if (response.status === 200) {
          setItems(response?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };

    fetchData();
  }, []);



  const toggleItem = (index) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter((item) => item !== index);
      } else {
        return [...prevOpenItems, index];
      }
    });
  };

  return (
    <section className="accordian-section-wrapper primary-color section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p className="heading text-center secondary-color-text">
              Have any Doubts?
            </p>
            <h4 className="text-center">Frequently Asked Questions</h4>
            <Accordion>
              {items.map((dataItem, i) => (
               <AccordionItem
               header={
                 <div className="accordion-header" onClick={() => toggleItem(i)}>
                   {dataItem.question}
                   <FontAwesomeIcon
                     icon={
                       openItems.includes(i) ? faChevronUp : faChevronDown
                     }
                     className="arrows-icons"
                     key={dataItem.id}
                   />
                 </div>
               }
               key={i}
               {...(openItems.includes(i) ? { expanded: 'true' } : {})}  
             >
               {dataItem.answer}
             </AccordionItem>
             
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccordianSection;

import React from "react";
import Accordion from "react-bootstrap/Accordion";
const AccordianMutual = () => {
  const data = [
    {
      id: 1,
      header:"Accordian1",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: 2,
      header:"Accordian2",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: 3,
      header:"Accordian3",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: 4,
      header:"Accordian4",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: 5,
      header:"Accordian5",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
    },
    {
        id: 6,
        header:"Accordian6",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim adminim veniam quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat Duis aute irure dolor i reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur Excepteur sint occaecat cupidatat non proident sunt inculpa qui officia deserunt mollit anim id est laborum.",
      },
  ];
  return (
    <div className="p-4">
      <Accordion defaultActiveKey="0">
        {
            data.map((item)=>(
                <Accordion.Item eventKey={item.id} key={item.id}>
                <Accordion.Header>{item.header}</Accordion.Header>
                <Accordion.Body>
                 {item.body}
                </Accordion.Body>
              </Accordion.Item>
            ))
        }
     
     
      </Accordion>
    </div>
  );
};

export default AccordianMutual;

import React from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Connect from '../components/ContactPages/Connect'
import Social from '../components/ContactPages/Social'
import Banner4 from '../components/ContactPages/Banner4'
import Map from '../components/ContactPages/Map'
import ContactForm from '../components/ContactPages/ContactForm'
const ContactUs = () => {
  return (
    <>
      <Header/>
      <Banner4/>
      <ContactForm/>
      <Connect/>
      <Social/>
      
      <Map/>
    
      <Footer/>
    </>
  )
}

export default ContactUs

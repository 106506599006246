import { createSlice } from "@reduxjs/toolkit";

const initialAadhaarState = {
  aadhaar: null, // Initial state for Aadhaar number
};

const aadhaarSlice = createSlice({
  name: "aadhaar",
  initialState: initialAadhaarState,
  reducers: {
    setAadhaar: (state, action) => {
      state.aadhaar = action.payload;
    },
    clearAadhaar: (state) => {
      state.aadhaar = null;
    },
  },
});

export const { setAadhaar, clearAadhaar } = aadhaarSlice.actions;

export default aadhaarSlice.reducer;

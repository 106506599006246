import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Benefit from "../pages/Benefit";
import ContactUs from "../pages/ContactUs";
import Login from "../pages/Login";
import Faq from "../pages/Faq";
import Profile from "../components/profilePages/Profile";
import PenCard from "../pages/PenCard";
import Portfolio from "../components/PenCardPage/Portfolio";
import SelectMutual from "../components/SelectedMutual/SelectMutual";
import StepsDemo from "../pages/StepsDemo";
import TermsCondition from "../components/TermsCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclamer from "../components/Disclamer";
import KycPage from "../components/KycPage/Kyc";
import BankPage from "../components/BankPage/Bank";
import PledgePage from "../components/pledgePage/PledgePage";
import AgreementPage from "../components/AgreemnetPage/Agreement";
import EmanDate from "../components/EmandatePage/EmanDate";
import KycPan from "../components/KycPage/KycPanCard";
import UserProfile from "../components/profilePages/UserProfile";
import KycOtp from "../components/KycPage/KycOtp";
import LoanPage from "../components/LoanPage/LoanPage";
import WebCam from "../components/KycPage/WebCam";
import KycQrCode from "../components/KycPage/KycQrCode";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/benefit" element={<Benefit />}></Route>
        <Route path="/contact" element={<ContactUs />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/user-profile" element={<UserProfile />}></Route>
        <Route path="/pan-card" element={<PenCard />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/selected-mutual" element={<SelectMutual />}></Route>
        <Route path="/steps-demo" element={<StepsDemo />}></Route>
        <Route path="/terms" element={<TermsCondition />}></Route>
        <Route path="/privacy" element={<PrivacyPolicy />}></Route>
        <Route path="/disclamer" element={<Disclamer />}></Route>
        <Route path="/kyc" element={<KycPage />}></Route>
        <Route path="/bank" element={<BankPage />}></Route>
        <Route path="/pledge" element={<PledgePage />}></Route>
        <Route path="/agreement" element={<AgreementPage />}></Route>
        <Route path="/emandate" element={<EmanDate />}></Route>
        <Route path="/kyc-otp" element={<KycOtp />}></Route>
        <Route path="/kyc-pan" element={<KycPan />}></Route>
        <Route path="/loan" element={<LoanPage />}></Route>
        <Route path="/webcam" element={<WebCam />}></Route>
        <Route path="/qr-code" element={<KycQrCode />}></Route>
      </Routes>
    </>
  );
};

export default Router;

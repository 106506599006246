import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../axios/api";
import NumberPatternDisplay from "./NumberPatternDisplay";
import { useDispatch } from "react-redux";
import { fetchToken } from "../store/slice";
// import { useSelector } from "react-redux";
const Login = () => {
  const [showModal, setShowModal] = useState(true);
  const [showOTPField, setShowOTPField] = useState(false);
  const [resendValue, setReSend] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [otperror, setOtpError] = useState("");
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const tokenData = useSelector((state) => state.token.token);
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  // };

  // var data = [
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  //   {
  //     img: "/assets/images/OBJECTS.png",
  //   },
  // ];

  useEffect(() => {
    let intervalId;

    if (showOTPField && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [showOTPField, timer]);

  const closeModal = () => {
    setShowModal(false);
    navigate("/");
  };
  const handleNumber = (e) => {
    const newPhoneNumber = e.target.value;
    const numericValue = newPhoneNumber.replace(/\D/g, "");
    setPhoneNumber(numericValue);
    if (numericValue.length !== 10) {
      setError("Only 10 digit Phone Number allowed");
    } else {
      setError("");
    }
  };
  const handleotp = (e) => {
    const newOtpNumber = e.target.value;
    const numericValue = newOtpNumber.replace(/\D/g, "");
    setOtp(newOtpNumber);
    if (numericValue.length !== 6) {
      setOtpError("Only 6 digit OTP Number allowed");
    } else {
      setOtpError("");
    }
  };
  const handleBackspace = (e) => {
    if (e.keyCode === 8 && error) {
      setError("");
    }
  };
  const handleOtpBackspace = (e) => {
    if (e.keyCode === 8 && otperror) {
      setOtpError("");
    }
  };
  const handleSendOTP = async (e) => {
    e.preventDefault();
    const trimmedPhoneNumber = phoneNumber.trim();
    if (!trimmedPhoneNumber) {
      toast.error("Please fill the phone number");
      return;
    }

    try {
      const response = await api.post(`send_otp`, {
        phone: trimmedPhoneNumber,
        type: "login",
      });
      if (response.status === 200) {
        setShowOTPField(true);
        toast.success("OTP sent successfully");
        // setTimer(30);
      } else {
        console.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();

    try {
      const otpNumber = otp.trim();
      if (!otpNumber) {
        toast.error("Please fill the OTP number");
        return;
      }
    
      const response = await api.post(`verify_otp`, {
        phone: phoneNumber,
        otp: otpNumber,
        type: "login",
      });
    
      if (response.status === 200) {
        const token = response.data.token;
        const is_registered = response.data.is_registered;
        
        console.log("is_registered:", is_registered);
        
        dispatch(fetchToken(token));
        
        if (is_registered === 1) {
          navigate("/profile");
          toast.success("OTP verified successfully and kyc completed");
        } else if (is_registered === 0) {
          navigate("/");
          toast.error("Please first completed Kyc");
        }
      } else {
        navigate("/login");
        toast.error("Failed to verify OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An error occurred while verifying OTP. Please try again.");
    }
  };
  const resetTimer = async () => {
    setTimer(30);
    try {
      const response = await api.post(`send_otp`, {
        phone: phoneNumber,
        type: "login-otp",
      });
      if (response.status === 200) {
        setShowOTPField(true);
        setReSend(true);
        toast.success("OTP Resend successfully");
        // setTimer(30);
      } else {
        console.error("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
    setTimeout(() => {
      setReSend(false);
    }, 30000);
  };
  return (
    <>
      <ToastContainer autoClose={500} className="data-toast" />
      {showModal && (
        <div className="modal-wrapper">
          <div className="modal-overlay">
            <button className="closed-button" onClick={closeModal}>
              X
            </button>
            <div className="modal-class">
              <div className="modal-content">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="login-container-wrapper">
                      <h5>
                        Welcome to{" "}
                        <span className="secondary-color-text">
                          Bulwark Capital
                        </span>
                      </h5>
                      <form
                        onSubmit={
                          showOTPField ? handleVerifyOTP : handleSendOTP
                        }
                      >
                        <div className="form-group form-wrapper-login">
                          <label htmlFor="phoneNumber" className="dataOne">
                            Phone Number
                          </label>
                          <input
                            type="phone"
                            className="form-control"
                            id="phoneNumber"
                            value={phoneNumber}
                            placeholder="Enter your Phone Number"
                            onChange={handleNumber}
                            onKeyDown={handleBackspace} 
                          />
                        </div>
                        {error.length > 0 && (
                          <span className="error-text">{error}</span>
                        )}
                        {showOTPField && (
                          <div className="form-group form-wrapper-login">
                            <label htmlFor="otp" className="dataOne">
                              OTP
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="otp"
                              value={otp}
                              placeholder="Enter your OTP"
                              onChange={handleotp}
                              onKeyDown={handleOtpBackspace}
                            />
                            {otperror.length > 0 && (
                              <span className="error-text">{otperror}</span>
                            )}
                          </div>
                        )}
                        <div className="content-data-login">
                          {showOTPField && (
                            <div className="text-center mt-1">
                              <span>
                                00:{timer < 10 ? `0${timer}` : timer}sec
                              </span>
                            </div>
                          )}
                          {showOTPField && (
                            <div className="text-center mt-1">
                              <NavLink
                                href="#"
                                className="data-href"
                                onClick={resendValue ? null : resetTimer}
                                aria-disabled={resendValue ? true : undefined}
                              >
                                Resend OTP
                              </NavLink>
                            </div>
                          )}
                        </div>
                        {showOTPField && (
                          <div className="text-center my-5">
                            <span>
                        
                              Enter OTP sent to&nbsp;
                              <NumberPatternDisplay
                                latesPhoneNumber={phoneNumber}
                              />
                            </span>
                          </div>
                        )}
                        <div className="form-check">
                          <img src="/assets/images/Iicon.png" alt="data" />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            By continuing to Verify, I agree to Bulwark Capital's <NavLink to="/terms" target="_blank" className="secondary-color-text"> Terms & Conditions </NavLink>
                            and the <NavLink to="/privacy" target="_blank" className="secondary-color-text">Privacy Policy 
</NavLink>
                            .
                          </label>
                        </div>
                        <button type="submit" className="button-content w-100">
                          {showOTPField ? "Verify" : "Send OTP"}
                        </button>
                      </form>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 primary-color"
                    style={{
                      borderTopRightRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    {/* <Slider {...settings} className="slider-wrapper">
                      {data.map((item, index) => ( */}
                        <div className="login-slider-wrapepr " >
                          <img src="/assets/images/OBJECTS.png" alt="curret" />
                          <h6 className="text-center">
                            Access Bulwark Capital's Loan
                          </h6>
                          <p className="text-center data-contents secondary-color-text">
                          Streamlined,
                          Convenient,
                          Simplified
                          </p>
                        </div>
                      {/* ))}
                    </Slider> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

import React, { useState } from 'react';

const ContactForm = () => {
  const formData = {
    name: '',
    email: '',
    mobile: '',
    message: ''
  };
  const [values, setValues] = useState(formData);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="section-padding contact-form-wrapper pt-0">
        <div className="container">
          <h5 className="text-center">Contact Us</h5>
          <form>
            <div className="row">
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Your Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="mobile">Mobile</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Your Mobile Number"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Your Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control mt-1"
                  placeholder="Enter Your Message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="mx-auto">
              <button className="btn btn-danger mx-auto w-100 mt-3">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

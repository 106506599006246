import React from 'react'

const NumberPatternDisplay = ({latesPhoneNumber}) => {
    const number=latesPhoneNumber.toString();
    const prefix=number.slice(0,1);
    const marked="*******";
    const suffix=number.slice(-2);
  return (
    <span>
      {prefix}
      {marked}
      {suffix}
    </span>
  )
}

export default NumberPatternDisplay


import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    storeData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { storeData } = dataSlice.actions;
export default dataSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialTokenState = {
  token: localStorage.getItem("token") || null,
};

const tokenSlice = createSlice({
  name: "token",
  initialState: initialTokenState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      // Avoid localStorage in reducer, handle this outside
    },
    clearToken: (state) => {
      state.token = null;
      // Avoid localStorage in reducer, handle this outside
    },
  },
});

export const { setToken, clearToken } = tokenSlice.actions;

export const fetchToken = (dataToken) => async (dispatch) => {
  try {
    dispatch(setToken(dataToken));
    localStorage.setItem("token", dataToken);
  } catch (error) {
    console.error("Failed to fetch token", error);
  }
};

export const logout = () => (dispatch) => {
  try {
    dispatch(clearToken());
    localStorage.removeItem("token");
  } catch (error) {
    console.error("Failed to log out", error);
  }
};

const tokenReducer = tokenSlice.reducer;

export default tokenReducer;
import React from 'react'
const data=[
  {
    heading:"Whatsapp Us",
    number:"+91 9999990055",
    img:"/assets/images/redwhatsapp.png",
    para:"Our team of experts is available on Mon-Sat from 10:00 AM to 5:00 PM",
    buttonContent:"WhatsApp Us"
  },
  {
    heading:"Call us",
    number:"+91 9999990055",
    img:"/assets/images/redPhone.png",
    para:"Our team of experts is available on Mon-Sat from 10:00 AM to 5:00 PM",
    buttonContent:"Call Now"
  },
  {
    heading:"Email Us",
    number:"support@bulwarkcapital.in",
    img:"/assets/images/reGmail.png",
    para:"You can also email us your queries. Our team will respond within 2 hour",
    buttonContent:"Send mail"
  }
]
const Connect = () => {
  return (
    <section className='section-padding mission-wrapper primary-color'>
      <h3 className='text-center' style={{marginBottom:"20px"}}>Let's connect and get your questions answered</h3>
      <div className='container'>
        <div className='row'>
          {
            data.map((item,index)=>(
              <div className='col-lg-4' key={index} >
              <div className='connect-wrapper' style={{marginTop:"20px"}}>
                    <div className='connect-content-wrapper'>
                        <div className='connect-media-wrapper'>
                          <h6>{item.heading}</h6>
                          <p>{item.number}</p>
                        </div>
                        <div className='connect-media-images'>
                          <img src={item.img} alt="current-data"/>
                        </div>
                    </div>
                    <div className='connect-end-wrapper'>
                      <p>{item.para}</p>
                      <div className='button-connect'>
                        <button className='button-content w-50'>{item.buttonContent}</button>
                      </div>
                    </div>
              </div>
          </div>
            ))
          }
         
        </div>

      </div>
    </section>
  )
}

export default Connect

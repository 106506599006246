import React from "react";
import { NavLink } from "react-router-dom";
const data = [
  {
    id: 1,
    links: `https://www.facebook.com/share/PHBVrh1ZJaipwwox/?mibextid=LQQJ4d`,
    img: "/assets/images/facebook.png",
  },
  {
    id: 2,
    links: `https://www.instagram.com/bulwarkcapital?igsh=cmJ0ZG9sbHN6eTQ4&utm_source=qr`,
    img: "/assets/images/whiteinstagram.png",
  },
  {
    id: 3,
    links: `https://x.com/Bulwarkcapital`,
    img: "/assets/images/X Icon.png",
  },
  {
    id: 4,
    links: `https://www.linkedin.com/company/bulwarkcapital/`,
    img: "/assets/images/Linedin.png",
  },
];
const Social = () => {
  return (
    <section className="section-pading">
      <div className="container">
        <span className="secondary-color-text text-center d-block">
          Social Media
        </span>
        <h3 className="text-center" style={{ marginBottom: "20px" }}>
          Connect with us on social media and Stay up-to-date with our latest
          products by following us
        </h3>
        <div className="social-media-wrapper">
          <div className="soical-media-image">
            {data.map((item) => (
              <div key={item.id} className="img-social">
                <NavLink to={item.links} target="_blank"><img src={item.img} alt="current-data" /></NavLink>
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Social;

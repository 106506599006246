import React from "react";
import { NavLink } from "react-router-dom";

const data=[
    {
      id:"1",
        heading:"Instant Disbursal",
        para:"Unlock cash from your Investments. Don't sell, borrow smartly with loan against mutual funds.",
        img:"/assets/images/Group4.png"
    },
    {
      id:"2",
        heading:"Interest rate @11% ",
        para:"Fixed proceesing fees",
        img:"/assets/images/Group3.png"
    },
    {
      id:"3",
        heading:"Repay Easily",
        para:"As per your convenience with Emi Or Monthly interest-only payments",
        img:"/assets/images/Group2.png"
    },
    {
      id:"4",
        heading:"Secure & 100% Digital",
        para:"We ensure safety through secure integration with your partners",
        img:"/assets/images/Group1.png"
    }
   
]


const OfferingTwo = () => {
  return (
    <section className="section-padding offering-wrapepr">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="offering-content-wrapper">
              {/* <span className="secondary-color-text my-3 heading">
             
              BENEFITS OF BULWARK CAPITALS
              </span> */}
              <h4 className="my-3">
                Bulwark Capital's Offerings at your fingertips
              </h4>
              <div className="offering-content-details">
               
                    {
                        data.map((item)=>(
                             <div className="offering-contents mt-2" key={item.id}>
                             <div className="img-offering" >
                             <img src={item.img} alt="current-data" />
                          </div>
                          <div className="offering-lead-data">
                            <h5>{item.heading}</h5>
                            <p>{item.para}</p>
                          </div>  
                          </div>
                           
                        ))
                    }
           
                <div className="button-wrapper">
                    <NavLink className="button-content mt-3 text-white"  to="/pan-card"  style={{width:"300px"}}>Check Eligibility in 15 Seconds</NavLink>    
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offering-wrapper">
              <img src="/assets/images/offering.png" alt="offering-data" />
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default OfferingTwo;



import React, { useState } from "react";
import SpinnerData from "../SpinnerData";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import api from "../../axios/api";
import { useDispatch } from "react-redux";
import { storeData } from "../../store/dataslice";
const PenCardForm = ({setPenCard,setOtpShow,setPhoneNumber,setPortfolio}) => {
  const [loadingdata,setLoadingData]=useState(false);
  const dispatch=useDispatch();
  const[valuesData,setValueData]=useState({mobile:"",panNumber:""});
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    let formattedValue = value;
    if (name === "mobile") {
      formattedValue = value.replace(/\D/g, "");
      formattedValue = formattedValue.slice(0, 10);
    }
        setValueData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };
    const handlesubmit =async (e)=>{
    e.preventDefault();
    var mobileNumber=valuesData.mobile.trim();
    let pan=valuesData.panNumber.trim();
    try{
      if(!mobileNumber){
        toast.error("Please Fill the Number");
        return;
      }
      if(!pan){
        toast.error("Please Fill the PAN");
        return;
      }
      setLoadingData(true);
      const response = await api.post(`validate-user`, {
        mobile: mobileNumber,
        pan: pan,
      }); 
      console.log("response data====>",response.data);
      dispatch(storeData(response.data));
     
      if(response.status===200){
       
         setPhoneNumber(mobileNumber);
         setOtpShow(true)
         setPenCard(false)
         setPortfolio(false);
         
         toast.success("Verify OTP successfully");
      }
        
    }catch(error){
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoadingData(false);
    }
    }
  return (
    <div>
      <ToastContainer autoClose={500} className="data-toast" />
    {loadingdata && <SpinnerData/>}
    <h1 className="text-center content-heading" style={{paddingTop:"140px;"}}>
          <b className="secondary-color-text">Get loan against mutual funds.</b>
          FREE eligibility check in 15 seconds
        </h1>
      <div className="card-pen-wrapper">
     
        <h4 className="text-center">
         Fetch Your Mutual Fund Portfolio
        </h4>
        <p className="text-center text-content-data">
          <img
            src="/assets/images/check-green.png"
            className="img-data"
            alt="current-data"
          />
          No impact on CIBIL score
        </p>
        <form  onSubmit={handlesubmit}>
          <div className="form-group form-wrapper-login">
            <label htmlFor="mobile" className="dataOne">
              Mobile No
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Mobile Number"
              value={valuesData.mobile}
              name="mobile"
              onChange={handleChange}
            />
          </div>
          <div className="form-group form-wrapper-login">
            <label htmlFor="panNo" className="dataOne">
              PAN
            </label>
            <input
              type="text"
              className="form-control"
              name="panNumber"
              value={valuesData.panNumber}
              placeholder="Enter Your Pan"
              onChange={handleChange}
            />
          </div>
          <button type='submit' className="button-content w-100 mt-4">
            Check Eligibility for Free
          </button>
          <p className="mt-3 text-center font-size-data">
               
          By Proceeding I accept<span><NavLink to="/terms" className="secondary-color-text" target="_blank"> T&C,</NavLink></span> and the<span>
            <NavLink to="/privacy" className="secondary-color-text" target="_blank"> Privacy Policy </NavLink>
            </span>
            
            and Authorize to obtain my KYC &
            Bureau Information.
          </p>
        </form>
      </div>

    
    </div>
  );
};

export default PenCardForm;

import React from "react";

import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="p-3 bg-dark footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-5">
              <div className="img-footer">
                <img src="/assets/images/footerLogo.png" alt="current_Data" />
              </div>
              <div className="footer-wrapepr-content">
                <h5 className="text-white">About Bulwark Capital</h5>
                <p className="text-white">
                  {" "}
                  Bulwark Capital is a cutting-edge digital platform that revolutionizes the way individuals access loans by allowing them to leverage their mutual funds as collateral. Launched with a focus on seamless, 100% digital transactions, Bulwark Capital offers a hassle-free borrowing experience. Users can quickly obtain loans against their mutual fund holdings, choosing between paying monthly interest or opting for EMI repayments.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row footer-wrapepr-content-two">
                <div className="col-lg-6">
                  <h5 className="text-white">Quick Links</h5>
                  <div className="link-footer-wrapper">
                    <div className="text-white">
                      <NavLink to="/faq" target="_blank">FAQs</NavLink>
                    </div>
                    <div className="text-white">
                      <NavLink to="/privacy" target="_blank">Privacy Policy</NavLink>
                    </div>
                    <div className="text-white">
                      <NavLink to="/terms" target="_blank">Terms & Conditions</NavLink>
                    </div>
                    <div className="text-white">
                      <NavLink to="/disclamer" target="_blank">Disclamer</NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h5 className="text-white">Follow Us:</h5>
                  <div className="subheader-icon-container">
                    <div className="icon-wrapper-content">
                      <a
                        href="https://x.com/Bulwarkcapital"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 299 300"
                          fill="none"
                        >
                          <path
                            d="M0 0.0902516C27.89 0.0902516 55.79 0.170252 83.68 0.000251599C87.57 -0.0197484 89.91 1.15025 92.15 4.36025C115.78 38.2503 139.57 72.0302 163.32 105.84C164.17 107.04 165.06 108.21 166.25 109.82C170.7 104.82 175 100.04 179.25 95.2103C206.24 64.5603 233.18 33.8603 260.29 3.32025C261.79 1.63025 264.6 0.360252 266.88 0.240252C273.94 -0.159748 281.03 0.100252 289.29 0.100252C251.78 42.7603 215.02 84.5603 177.98 126.69C218.4 184.27 258.7 241.69 299 299.1H209.3C208.8 298.09 208.43 296.99 207.79 296.07C182.19 259.55 156.56 223.04 130.93 186.54C130.19 185.49 129.31 184.53 128.08 183.01C127.17 184.19 126.52 185.13 125.77 185.99C101.24 213.91 76.68 241.79 52.18 269.74C43.67 279.45 35.33 289.31 26.92 299.1C18.61 299.1 10.31 299.12 2 299.06C1.33 299.06 0.67 298.44 0.01 298.11C1.44 296.82 3.02 295.65 4.29 294.21C33.91 260.57 63.5 226.9 93.1 193.23C100.98 184.27 108.83 175.29 116.66 166.36C116 165.35 115.58 164.64 115.1 163.96C77.61 110.58 40.14 57.2202 2.63 3.87025C1.91 2.84025 0.88 2.01025 0 1.09025V0.0902516ZM262.64 280.63C261.4 278.71 260.82 277.75 260.18 276.85C199.7 192.15 139.23 107.44 78.61 22.8403C77.36 21.0903 74.36 19.7503 72.13 19.6803C61.84 19.3503 51.54 19.5203 41.24 19.5503C39.86 19.5503 38.47 19.8003 36.48 20.0003C37.83 22.0503 38.77 23.5703 39.8 25.0203C70.95 68.6503 102.13 112.27 133.28 155.91C162.02 196.17 190.73 236.45 219.55 276.65C220.76 278.34 222.94 280.42 224.71 280.46C236.98 280.79 249.26 280.64 262.63 280.64L262.64 280.63Z"
                            fill="black"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="icon-wrapper-content">
                      <a
                        href="https://www.instagram.com/bulwarkcapital?igsh=cmJ0ZG9sbHN6eTQ4&utm_source=qr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img src="/assets/images/icon3.png" alt="current_row" />
                      </a>
                    </div>
                    <div className="icon-wrapper-content">
                      <a
                        href="https://www.facebook.com/share/PHBVrh1ZJaipwwox/?mibextid=LQQJ4d"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img src="/assets/images/icon4.png" alt="current_row" />
                      </a>
                    </div>
                    <div className="icon-wrapper-content">
                      <a
                        href="https://www.linkedin.com/company/bulwarkcapital/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <img
                          src="/assets/images/iconLinkedIn.png"
                          alt="current_row"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-white"></hr>
            <div className="last-footer-content px-5">
              <div className="text-white">
                ©2024 BULWARK CAPITAL PRIVATE LIMITED | RBI Reg No. N-14.03394
              </div>
              <div className="text-white">CIN:U65999HR2017PTC068207</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

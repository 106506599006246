import React from "react";

const Banner2 = () => {
  return (
    <>
      <section className="section-padding padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-two-wrapper">
                <div className="banner-two-content">
                  <h2>
                    India's premier platform offers &nbsp;
                    <span className="secondary-color-text">
                      instant loans against mutual funds&nbsp;
                    </span>
                    granting swift credit to millions in under 5 minutes.
                  </h2>
                  <p className="mt-4">
                    Bulwark Capital is a cutting-edge digital platform that
                    revolutionizes the way individuals access loans by allowing
                    them to leverage their mutual funds as collateral. Launched
                    with a focus on seamless, 100% digital transactions, Bulwark
                    Capital offers a hassle-free borrowing experience. Users can
                    quickly obtain loans against their mutual fund holdings,
                    choosing between paying monthly interest or opting for EMI
                    repayments.
                  </p>
                  <p>
                    This innovative approach ensures that your financial assets
                    remain intact while providing a flexible and efficient
                    solution for short- or medium-term financial needs. By using
                    a secured loan against mutual funds, you can access
                    necessary funds without liquidating your investments, making
                    Bulwark Capital a smart choice for managing immediate
                    financial requirements while keeping your investment
                    portfolio intact.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper align-item-center">
                <img
                  src="/assets/images/man-suit-with-shirt-that-says-he-s-smiling 1.png"
                  alt="data_1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner2;

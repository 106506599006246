import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import AccordianMutual from './AccordianMutual'
import { NavLink } from 'react-router-dom'

const SelectMutual = () => {
  return (
    <div>
        <Header/>
        <div className='section-padding port-wrapper'>
            <div className='container pt-5'>
                <div className='row mt-5'>
                    <div className='col-lg-12'>
                        <div className='upper-content'>
                        <h5>Selected creadit Limits</h5>
                        <p className='text-data-one' style={{borderBottom:"1px solid #eeee"}}>₹50,000</p> 
                        <div>
                            <h6>Selected Mutual Funds</h6>
                            <p>₹1,11,200 out of ₹4,23,600 are select for Pleadging</p>
                        </div>  
                        </div>
                    </div> 
                        {/* accordian data start */}
                        <div className='accordian-mutual-wrapper'>
                            <h5>Lading Partners</h5>
                            <h4 className='mt-4'>Interest & Other Charges</h4>
                            <AccordianMutual/>
                            <div className='mb-4'>
                                <NavLink to="/steps-demo" className='button-content w-50 mx-auto text-white'>Continue</NavLink>
                            </div>
                        </div>
                      
                        {/* accordian data end */}
                </div>
            </div>
        </div>
    
      <Footer/>
    </div>
  )
}

export default SelectMutual


import axios from 'axios';
// import { useSelector } from "react-redux"
const instance = axios.create({
  baseURL: 'https://bulwark.marioxsoftwaredevelopmentagency.com/staging/api/v1/', 
  
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  function(config) {
   
    const authToken = config.headers['Authorization'];
    if (authToken) {
      config.headers['Authorization'] = ` ${authToken}`;
    }
    return config;
  },
  function(error) {
   
    return Promise.reject(error);
  }
);

export default instance;
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router'

const StepsDemo = () => {
 const navigate=useNavigate();
  const data=[
    {
      id:1,
      content:1,
      heading:"KYC Verifications",
      para:"verify Aadhar &n other details to complete kyc"
    },
    {
      id:2,
      content:2,
      heading:"Setup bank and autopay",
      para:"provide bank account for receiving money"
    },
    {
      id:3,
      content:3,
      heading:"Mark portfolio as Security",
      para:"Mark portfolio as Security"
    },
    {
      id:4,
      content:4,
      heading:"Sign loan Agrement",
      para:"verify the key usge terms and confirms"
    }
  ]
  const handleStart=()=>{
    navigate("/kyc");
  }
  return (
    <div>
   <Header/>
   <div className='section-padding port-wrapper'>
   <div className='container pt-5'>
    <div className='row mt-5'>
      <div className='col-lg-12'>
        <h6 className='text-content'>Only 4 steps Remaning</h6>
        <div className='steps-demo-wrapper'>
          <div className='step-image-data'>
            <img src='https://placehold.co/90x90' alt="current-data"/>
            
          </div>
          <div className='step-demo-content'>
            <p className='mb-0'>creadit limit</p>
            <h4>₹50,000</h4>
          </div>
        </div>
        {/* steps data start */}
        <div>
          <div className='step-data-wrap'>
            {
              data.map((item)=>(
              <div className='steps-data-child' key={item.id}>
                 <div className='content'>{item.content}</div>
                <div>
                  <p className='fw-bold mb-0'>{item.heading}</p>
                  <span style={{fontSize:"12px"}}>{item.para}</span>
                </div> 
              </div>
              ))
            }
            
          </div>
        
        </div>
        {/* steps data end */}
        </div>
        <button className='button-content w-25 mt-4 mx-auto' onClick={handleStart}>Start</button>
    </div>
   </div>
  

   </div>
   
   <Footer/>
    </div>
  )
}

export default StepsDemo

import React from 'react'

const Banner4 = () => {
  return (
    <section className='section-padding banner-four-wrapepr padding-top'>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-6 align-self-center'>
                <div className='banner-wrapper-content'>
                    <h1>Get in <span className='secondary-color-text'>Touch</span></h1>
                    <p> At Bulwark Capital, our goal is to offer you the very best products and services. We value your feedback and want to ensure that we are meeting your expectations. If you have any comments on our products or services, or need to share a concern, please reach out to us through any of the following channels:</p>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='img-banner-four'>
                <img src="/assets/images/banner4.png" alt="data-banner" className='data-content' style={{width:"500px"}}/> 
                </div>
            </div>
        </div>

      </div>
    </section>
  )
}

export default Banner4

import React,{useState} from "react";
import { NavLink } from "react-router-dom";

const EmanDate = () => {
    const [formData, setFormData] = useState({
        accountHolderName: '',
        accountType: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        mobileNumber: '',
        mandateAmount: '',
        frequency: '',
        purposeOfMandate: '',
        amountType: '',
        startDate: '',
        expiryDate: '',
        corporateName: '',
        utilityCode: '',
        referenceNo: '',
        authMode: '', // Net Banking, Debit Card, or Aadhar
        agreeTerms: false,
      });
    
    
      const handleChange = (e) => {
        const { id, value, type, checked, name } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [id]: type === 'checkbox' ? checked : value,
          [name]: type === 'radio' ? value : prevState[name]
        }));
      };
    
 
      const handleSubmit = (e) => {
        e.preventDefault();
   
        console.log(formData);
      };
  return (
    <div className="section-padding primary-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="card p-3">
              <div className="card-body">
                <h5 className="text-center">Register to Emandate</h5>
                <hr
                  style={{
                    borderBottom: "1px solid red",
                    width: "250px",
                    margin: "0 auto",
                  }}
                />
              </div>
              <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="accountHolderName" className="dataOne">
                      Account Holder Name
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="accountHolderName"
                      placeholder="Enter Account Holder Name"
                      value={formData.accountHolderName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="accountType" className="dataOne">
                      Account Type
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="accountType"
                      placeholder="Enter Account Type"
                      value={formData.accountType}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="accountNumber" className="dataOne">
                      Account Number
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="accountNumber"
                      placeholder="Enter Account Number"
                      value={formData.accountNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="ifscCode" className="dataOne">
                      IFSC Code
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="ifscCode"
                      placeholder="Enter IFSC Code"
                      value={formData.ifscCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="bankName" className="dataOne">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="bankName"
                      placeholder="Enter Your Bank Name"
                      value={formData.bankName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="mobileNumber" className="dataOne">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="mobileNumber"
                      placeholder="Enter Your Mobile Number"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="mandateAmount" className="dataOne">
                      Mandate Maximum Amount
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="mandateAmount"
                      placeholder="Enter Your Mandate Maximum Amount"
                      value={formData.mandateAmount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="frequency" className="dataOne">
                      Frequency
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="frequency"
                      placeholder="Enter Your Frequency"
                      value={formData.frequency}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="purposeOfMandate" className="dataOne">
                      Purpose of Mandate
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="purposeOfMandate"
                      placeholder="Enter Your Purpose of Mandate"
                      value={formData.purposeOfMandate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="amountType" className="fw-bold">
                      Type Of Amount
                    </label>
                    <div className="d-flex">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="maxAmount"
                          value={formData.amountType}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="maxAmount">
                          Max Amount
                        </label>
                      </div>
                      <div className="form-check mt-2 mx-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="fixedAmount"
                          value={formData.amountType}
                          checked
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="fixedAmount">
                          Fixed Amount
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="startDate" className="dataOne">
                      Start Date
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="startDate"
                      placeholder="Enter Your Start Date"
                      value={formData.startDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="expiryDate" className="dataOne">
                      Expiry Date (DD-MM-YYYY)
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="expiryDate"
                      placeholder="Enter Your Expiry Date"
                      value={formData.expiryDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="corporateName" className="dataOne">
                      Corporate Name
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="corporateName"
                      placeholder="Enter Your Corporate Name"
                      value={formData.corporateName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="utilityCode" className="dataOne">
                      Utility Code
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="utilityCode"
                      placeholder="Enter Your Utility Code"
                      value={formData.utilityCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="referenceNo" className="dataOne">
                      Reference No
                    </label>
                    <input
                      type="text"
                      className="form-control mt-2"
                      id="referenceNo"
                      placeholder="Enter Your Reference No"
                      value={formData.referenceNo}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="authMode" className="fw-bold">
                      Select Authentication Mode
                    </label>
                    <div className="d-flex">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="authMode"
                          id="netBanking"
                          value={formData.authMode}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="netBanking">
                          Net Banking
                        </label>
                      </div>
                      <div className="form-check mt-2 mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="authMode"
                          id="debitCard"
                          value={formData.authMode}
                          onChange={handleChange}
                          checked
                        />
                        <label className="form-check-label" htmlFor="debitCard">
                          Debit Card
                        </label>
                      </div>
                      <div className="form-check mt-2 mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="authMode"
                          id="aadhar"
                          value={formData.authMode}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="aadhar">
                          Aadhar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2">
                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="agreeTerms"
                      value={formData.agreeTerms}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="agreeTerms">
                      By Clicking Submit, I agree to Bulwork Capital{" "}
                      <NavLink to="/terms">
                        <span className="text-content">terms & conditions</span>
                      </NavLink>
                    </label>
                  </div>
                </div>
                <div className="btn-wrapper-data">
                  <button className="button-content mx-auto">Proceed</button>
                </div>
              </div>
              </form>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmanDate;

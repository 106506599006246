import React from 'react'
import Steps from '../StepsPage/Steps'

const BankPage = () => {
  return (
    <div>
     <Steps isBank={true}/>
    </div>
  )
}

export default BankPage

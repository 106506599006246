import React from 'react'
import Header from "../components/Header"
import Footer from "../components/Footer";
import Banner2 from "../components/AboutPage/Banner2";
import Mission from "../components/AboutPage/OurMisson"
// import Achivment from "../components/AboutPage/Achivment";
// import Founder from "../components/AboutPage/Founder";
import History from "../components/AboutPage/History";
import AccordianSection from '../components/AccordianSection';
const About = () => {
  return (
    <>
     <Header/> 
     <Banner2/>
     <Mission/>
     {/* <Achivment/> */}
     {/* <Founder/> */}
     <History/>
     <AccordianSection/>
     <Footer/>
    </>
  )
}

export default About

import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
// import SpinnerData from "../SpinnerData";
const Portfolio = () => {
  const[selectedCheckBox,setSelectedCheckBox]=useState([]);
  const [selectedSchemeName, setSelectedSchemeName] = useState("");
  const portfolioData = useSelector((state) => state.data.data.portfolio.data);
  console.log("portfolioData========>",portfolioData);
//  const [loading,setLoading]=useState(false);
 const navigate=useNavigate(); 
 const handleChange = (e,id,schemeName) => {
    if (e.target.checked) {
      console.log("running data 1");
      setSelectedCheckBox([...selectedCheckBox, id]);
      setSelectedSchemeName(schemeName);
    } else {
      console.log("running data 2");
      setSelectedCheckBox(selectedCheckBox.filter(item => item !== id));
      setSelectedSchemeName("");
    }
  };

  const handleClose=()=>{
    setSelectedCheckBox([]);
    setSelectedSchemeName("");
  }
  const updateAmount=()=>{
   navigate("/selected-mutual");
  }
 
  return (
    <>
      <Header />
      {/* {loading && <SpinnerData/>} */}
      <div className="section-padding port-wrapper ">
        <div className="container pt-5">
          <div className="row mt-3">
            <div className="col-lg-12">
              <h1 className="secondary-color-text">
                Select Mutual Funds for Pledging
              </h1>
              <p>₹1,11,200 out of ₹4,23,600 are select for Pleadging</p>
            </div>
            <div className="profolio-wrapper">
              <div className="portfolio-content">
                <h5>Mutual Funds</h5>
                <h5>Creadit Limits</h5>
              </div>
             {
              portfolioData.length>0 &&  portfolioData.map((item)=>(
                item.schemes.map((schamaData,index)=>(
                  <div className="portfolio-main" key={index}>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`flexCheckDefault-${index}`}
                        checked={selectedCheckBox.includes(index)}
                        onChange={(event) => handleChange(event, index,schamaData.schemeName)}
                      />
                      <label
                        className="form-check-label fw-bold" 
                        htmlFor={`flexCheckDefault-${index}`}
                      >
                        {schamaData.schemeName}
                      </label>
                      <p>value: ₹{schamaData.nav}</p>
                    </div>
                  </div>
                  <div className="creadit-limit">
                    <div>
                      <h6>₹{schamaData.currentMktValue}</h6>
                      <p>₹{schamaData.costValue}</p>
                    </div>
                    <div>
                      {/* <i className="fa fa-pen"></i> */}
                    </div>
                  </div>
                </div>
                
                ))
              ))
            }
             
            </div>
          </div>
        </div>
      </div>
      {selectedCheckBox.length >0  && (
        <div className="mutual-form-wrapper">
          <button onClick={handleClose} className="closeIcon">X</button>
          <div className="mutual-form-subwrapper">
            <form>
            <h6>{selectedSchemeName}</h6>
              <div className="form-group form-wrapper-login">
               
                <label htmlFor="otp" className="dataOne">
                  enter amount
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Amount"
                  name="enterAmount"
                />
              </div>
             <button className="button-content mt-3 w-50 mx-auto" onClick={updateAmount}>Update Amount</button>
            </form>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Portfolio;

import React from "react";

const Eligable = () => {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img-wrapper-content">
              <img
                src="/assets/images/smile (3).png"
                alt="run_data"
                className="eligable-img"
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="eligble-content-wrapepr about-section-wrapper">
              <h4 className="secondary-color-text d-block">
                Am I Eligible?
              </h4>
              <h3 className="">100% eligibility! Even for low CIBIL score</h3>
              <div className="check-wrapper">
                 <img src="/assets/images/check.png"  alt="check_data"/>
                 <p>No minimum credit score requirement (CIBIL score)</p>   
              </div>
              <div className="check-wrapper">
                 <img src="/assets/images/check.png"  alt="check_data"/>
                 <p>No restriction on employment status or profession</p>   
              </div>
              <div className="check-wrapper">
                 <img src="/assets/images/check.png"  alt="check_data"/>
                 <p>No minimum income requirement or bank statement</p>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Eligable;

import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Disclamer = () => {
  const disclaimerContent = [
    "You agree and understand that the information and material contained in this website implies and constitutes your consent to the Privacy Policy. You also agree that Bulwark Capital can modify or alter the terms and conditions of the use of this service without any liability.",
    "The contents of the site and the interpretation of data are solely the personal views of the contributors. Bulwark Capital reserves the right to make modifications and alterations to the content of the website.",
    "Users are advised to use the data for the purpose of information only and rely on their own judgment while making decisions regarding products and services on offer. The products discussed or recommended may not be suitable for all Clients/Customers. Bulwark Capital does not warranty the timeliness, accuracy or quality of the electronic content.",
    "The content of the website cannot be copied, reproduced, republished, uploaded, posted, transmitted or distributed for any non-personal use without obtaining prior permission from Bulwark Capital. We reserve the right to terminate the accounts of subscribers/customers, who violate the proprietary rights, in addition to necessary legal action.",
    "Bulwark Capital and its owners/affiliates are not liable for damages caused by any performance, failure of performance, error, omission, interruption, deletion, defect, delay in transmission or operations, computer virus, communications line failure, and unauthorized access to the personal accounts.",
    "Bulwark Capital is not responsible for any technical failure or malfunctioning of the software or delays of any kind. We are also not responsible for non-receipt of registration details or e-mails. Users shall bear all responsibility of keeping the password secure. Bulwark Capital is not responsible for the loss or misuse of the password. You shall not use the Content for illegal purposes.",
    "Bulwark Capital is not responsible for the content of any of the linked sites. By providing access to other websites, Bulwark Capital is neither recommending nor endorsing the content available in the linked websites.",
    "You agree that the information gathered from your profile will be used to enhance your experience on the website. In case of a contest or a promotion scheme, we reserve the right to share the user's profile with the sponsors. In the event of necessary credit checks and collection of payments, Bulwark Capital can disclose such information to other authorities in good faith. Bulwark Capital shall use all or any part of the services and change the terms without any obligation.",
    "Products and Services of the Company displayed or available herein are for information and do not constitute an offer to buy or sell or a solicitation in this regard made to any person. The Products and Services are not available to any person or entity who is a citizen or resident of or located in any locality, state, country or other jurisdiction, where availing of such services would be contrary to any applicable law or regulation of that jurisdiction or the laws of the Republic of India or which would subject the Company, its Directors, employees, associates, subsidiaries and/or affiliates to any registration or licensing requirement within such jurisdiction.",
    "The development of the Website with respect to the Content and products and services (\"Products\") offered through it, is a continuous and an ongoing process and Products are provided on a \"best effort basis\". Bulwark Capital shall make all attempts to keep the Products updated and accurate, but Products may not be up to date. Bulwark Capital makes no warranty or representation, express or implied, including, but not limited to any warranties or representations as to the accuracy, reliability or completeness of the Products and/or fitness for a particular purpose, and/or non-infringement with respect to the Website and/or Linked Websites and/or the Products.",
    "You or any third party accessing the Website irrevocably agrees to the exclusive jurisdiction of the courts at Cochin in relation to any matter connected or related to use or access of the Website and waives any objection to any proceedings on grounds of venue or on the grounds that the proceedings have been brought in an inconvenient forum. The governing law in such legal proceedings referred to above shall be the laws of the Republic of India.",
    "If you do not agree to any of the terms mentioned herein above, you are requested to exit the site."
  ];

  return (
    <div>
      <Header />
      <div className="section-padding-two padding-top">
        <h5>Disclaimer</h5>
        {disclaimerContent.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Disclamer;

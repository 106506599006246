import React from 'react'
import Steps from '../StepsPage/Steps'

const UserProfile = () => {
    const handlechange=()=>{

    }
  return (
    <>
    <Steps isKyc={true}/>
    <section className='section-padding pt-0' >
    <div className='container'>
        <form style={{border:"1px solid #EF1C25",padding:"20px",borderRadius:"10px"}}>
        <div className='row'>
            <div className='col-lg-12'>
                <div className='text-center'>
                <img src="/assets/images/Group.png" alt="data1" className='user-profile-data'/>
                </div>
            
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Name</label>
                    <input
                    type="text"
                    className="form-control mt-3"
                    placeholder="Enter your Name"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Father Name</label>
                    <input
                    type="text"
                    className="form-control mt-3"
                    placeholder="Enter your Name"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Email</label>
                    <input
                    type="text"
                    className="form-control mt-3"
                    placeholder="Enter your Email"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Aadhar Number</label>
                    <input
                    type="text"
                    className="form-control mt-3"
                    placeholder="Enter your Aadhar Number"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Pan Number</label>
                    <input
                    type="text"
                    className="form-control  mt-3"
                    placeholder="Enter your Pan Number"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Kyc Status</label>
                    <input
                    type="text"
                    className="form-control mt-3"
                    placeholder="Kyc Status"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Loan Amount</label>
                    <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter your Loan Amount"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Active Loan</label>
                    <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter your Loan Amount"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-6'>
                <div className="form-group form-wrapper-login mt-3">
                    <label htmlFor="name" className='dataOne'>Bank Account No</label>
                    <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter your Bank Account Number"
                    name="name"
                    value=""
                    onChange={handlechange}
                    />
                </div>   
            </div>
            <div className='col-lg-12'>
            <div className="form-group form-wrapper-login mt-2">
                    <label htmlFor="name" className='dataOne'>Addrees</label>
                   <textarea className='form-control  mt-4' rows="5" placeholder='Enter your Address'></textarea>
                </div>
            </div>
        </div>
        <button className='button-content mt-3 mx-auto'>Go To Bank</button>
        </form>
        
        
    </div>      
     </section>
    </>
  )
}

export default UserProfile
import React from "react";
import { NavLink } from "react-router-dom";

const data=[
    {
      id:"1",
        heading:"Get LAMF Online Within Minutes",
        para:`No need to wait for days. Complete 5 simple steps to get a loan against mutual funds within minutes using  Bulwark Capital. 
        Your application will be processed instantly to provide you with a loan within minutes.`,
        img:"/assets/images/Group4.png"
    },
    {
      id:"2",
        heading:"Zero Foreclosure Charges",
        para:"No lock-in & no foreclosure charges if you decide to repay your outstanding's early. You can make payment towards your outstanding amount anytime with zero foreclosure charges.",
        img:"/assets/images/Group3.png"
    },
    {
      id:"3",
        heading:"Instant Disbursal",
        para:"Bulwark Capital provide access to funds whenever you need them. Get the required amount credited directly to your provided bank account instantly.",
        img:"/assets/images/Group2.png"
    },
    {
      id:"4",
        heading:"Large List of Approved Securities",
        para:" Select from a large list of approved mutual funds from different asset management companies (AMCs) in India. You can lien mark mutual funds registered with both CAMS & KFintech (earlier known as KARVY), Registrars & Transfer Agents (RTAs).",
        img:"/assets/images/Group1.png"
    },
    {
      id:"5",
        heading:"100% Digital Process",
        para:" No need of visiting branches or reaching out to relationship managers. With  Bulwark Capital you can complete your entire journey online from your mobile device without any need of submitting physical documents",
        img:"/assets/images/Group1.png"
    },
]

const data2=[
  {
    id:"6",
      heading:"Retain Ownership",
      para:"Allow your mutual funds to achieve long-term goals. You continue to retain ownership of your mutual funds and reap all the benefits that are associated with it.",
      img:"/assets/images/Group1.png"
  },
  
  {
    id:"8",
      heading:"Attractive Interest Rate",
      para:"Avail loan at an attractive Interest rate starting 11 % p.a",
      img:"/assets/images/Group1.png"
  },
  
  {
    id:"9",
      heading:"Loan Tenure",
      para:"The loan provided against your mutual funds has a tenure of 12 months and is renewed thereafter",
      img:"/assets/images/Group1.png"
  },
  {
    id:"10",
      heading:"Higher Loan Value",
      para:"One place for all your financial requirements. With Bulwark Capital you can get loan up to Rs 10 lakhs in case of equity mutual funds (45% LTV).",
      img:"/assets/images/Group1.png"
  },
  {
    id:"11",
      heading:"Easy Repayment",
      para:"Option to select from interest-only payments or EMIs.",
      img:"/assets/images/Group1.png"
  },
]
const Offering = () => {
  return (
    <section className="section-padding offering-wrapepr mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="offering-content-wrapper">
              {/* <span className="secondary-color-text my-3 heading">
             
              BENEFITS OF BULWARK CAPITALS
              </span> */}
              <h4 className="my-3">
                Bulwark Capital's Offerings at Your Fingertips
              </h4>
              <div className="offering-content-details">
               
                    {
                        data.map((item)=>(
                             <div className="offering-contents" key={item.id}>
                             <div className="img-offering" >
                             <img src={item.img} alt="current-data" />
                          </div>
                          <div className="offering-lead-data">
                            <h5>{item.heading}</h5>
                            <p>{item.para}</p>
                          </div>  
                          </div>
                           
                        ))
                    }
           
                <div className="button-wrapper">
                    <NavLink className="button-content mt-3 text-white"  to="/pan-card">Check Eligibility in 15 Seconds</NavLink>    
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="offering-wrapper">
              <img src="/assets/images/offering.png" alt="offering-data" />
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-lg-6">
            <div className="offering-wrapper">
              <img src="/assets/images/smile (1).png" alt="offering-data" />
            </div>
            </div>
          <div className="col-lg-6">
          <div className="offering-content-wrapper">
            <div className="offering-content-details">
               {
                   data2.map((item)=>(
                        <div className="offering-contents" key={item.id}>
                        <div className="img-offering" >
                        <img src={item.img} alt="current-data" />
                     </div>
                     <div className="offering-lead-data">
                       <h5>{item.heading}</h5>
                       <p>{item.para}</p>
                     </div>  
                     </div>
                      
                   ))
               }
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offering;

import React from "react";
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <section className="section-padding padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center align-items-center" style={{marginTop:"100px"}}>
              <div className="banner-content-wrapper">
                <h4 className="mb-4">
                  Your Financing Needs Simplified
                </h4>
               
                {/* <h4 className=" text-content mt-3"> INSTANT DISBURSAL</h4> */}
                <h6 className="fw-normal">
                Maintan Your Investment portfolio intact Convenient online
                  application process Lower interest rates compared to unsecured
                  loans.
                </h6>
                <h5 className="mt-4">
                  Unlock Cash From Your Investments. Don't Sell, Borrow Smartly
                  with  Loan Against Mutual Funds.
                </h5>
                <div className="mt-4">
                <span>Easy, fast & secure</span>
                <span className="mx-4"> Instant Disbursal</span>
                </div>
                
                <NavLink to="/pan-card"className="button-content text-white mt-4" style={{fontSize:"16px",width:"300px"}}>
                Check Eligibility In 15 Sec
                </NavLink>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-img">
                <img src="/assets/images/Group.png" alt="data1" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

import React, { useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Steps from '../StepsPage/Steps';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import api from "../../axios/api";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../SpinnerData";
import { useDispatch, useSelector } from "react-redux";
import { setAadhaar } from "../../store/sliceadhar";

const KycPage = () => {
  const [aadharNumber, setAadharNumber] = useState("");
  const [loadingdata, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const authToken = useSelector((state) => state.token.token);

  console.log(authToken,"authToken")
  const handleAadharNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    setAadharNumber(numericValue);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
  
    if (aadharNumber.length !== 12) {
      toast.error("Aadhaar number must be 12 digits long.");
      return;
    }
  
    try {
      setLoadingData(true);
  
      const response = await api.post('aadhar-otp', {
        aadhaar_number: aadharNumber
      }, authToken);
  
      console.log("Response Aadhaar:", response.data);
  
      if (response.status === 200) {
        dispatch(setAadhaar(response.data)); 
        setLoadingData(false);
        toast.success("Aadhaar OTP sent successfully");
        navigate("/kyc-otp");
      } else {
        setLoadingData(false);
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setLoadingData(false);
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer autoClose={5000} />
      {loadingdata && <Spinner />}
      <div className='section-padding kyc-wrapper-data'>
        <Steps isKyc={true} />
        <div className='card' style={{ width: "40%", margin: "0 auto" }}>
          <div className="card-body">
            <form onSubmit={handleAdd}>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="form-group form-wrapper-login">
                    <label htmlFor="aadharNumber" className="dataOne">Aadhar Number</label>
                    <input
                      type="text"
                      id="aadharNumber"
                      className="form-control"
                      placeholder="Enter your Aadhaar Number"
                      value={aadharNumber}
                      onChange={handleAadharNumberChange}
                      maxLength={12}
                    />
                  </div>
                </div>
                <div>
                  <button type="submit" className='button-content mt-3 w-25 mx-auto'>Verify Aadhar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default KycPage;

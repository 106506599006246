import React from 'react'

const Steps = (props) => {
    const { isKyc, isBank, isPledge, isAgreement,isDone } = props;
    return (
        <div className="cart_step_order mt-4">
          <div className="sec_container">
            <ul className="cart_step_container">
              <li className={isDone ? "done" : ""}>
                <i className={isKyc ? "fa fa-check checkData" : "fa fa-check"}></i>
                <span className={isKyc ? "bold_text" : ""}>
                kyc
                </span>
              </li>
              <li className={isDone ? "done" : ""}>
              <i className={isBank ? "fa fa-check checkData" : "fa fa-check"}></i>
                <span className={isBank ? "bold_text" : ""}>
                Pledge
                </span>
              </li>
              <li className={isDone ? "done" : ""}>
              <i className={isPledge ? "fa fa-check checkData" : "fa fa-check"}></i>
                <span className={isPledge ? "bold_text" : ""}>
                 Sign eAgreement
                </span>
              </li>
              <li className={isDone ? "done" : ""}>
              <i className={isAgreement ? "fa fa-check checkData" : "fa fa-check"}></i>
                <span className={isAgreement ? "bold_text" : ""}>
                Sign Auto-debit
                </span>
              </li>
            </ul>
          </div>
        </div>
      );
}

export default Steps

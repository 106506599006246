import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import api from "../../axios/api";
import { useSelector } from "react-redux";
import SpinnerData from "../SpinnerData";
const Profile = () => {
  const formData = {
    name: "",
    email: "",
    phone: "",
    address: "",
    aadhar: "",
  };
  const authToken = useSelector((state) => state.token.token);
  const [laoading, setLaoding] = useState(false);
  const [values, setValues] = useState(formData);

  const handlechange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    console.log(values);
  };
  const fetchData = async () => {
    try {
      const response = await api.get("profile", authToken);
      if (response.status === 200) {
        setValues(response?.data?.data);
      }
    } catch (error) {
      console.log("error===============>", error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLaoding(true);
      const response = await api.post(
        "update-profile",
        {
          name: values.name,
          fathername:values.fathername,
          pan:values.pan,
          bank:values.bank,
          phone: values.phone,
          email: values.email,
          address: values.address,
          aadhar: values.aadhar,
        },
        authToken
      );
      if (response.status === 200) {
        setValues(response?.data?.data);
        fetchData();
        setLaoding(false);
       window.location.reload();
      }
    } catch (error) {
      setLaoding(false);
      console.log("error===============>", error);
    }
  };

  return (
    <>
      <Header />
      {laoading && <SpinnerData />}
      <div className="section-padding profile-form">
        <div className="container">
          <div className="text-center">
            <h2>My Profile</h2>
          </div>
          <div className="profile-form-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="row">
               
                    <div className="form-group form-wrapper-login mt-1 col-lg-6">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        placeholder="Enter your Name"
                        name="name"
                        value={values.name}
                        onChange={handlechange}
                      />
                    </div>
               
              <div className="form-group form-wrapper-login mt-1 col-lg-6">
                <label htmlFor="fathername">Father Name</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter your Name"
                  name="name"
                  value={values.fathername}
                  onChange={handlechange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-6">
                <label htmlFor="phone">Phone</label>
                <input
                  type="phone"
                  className="form-control mt-1"
                  placeholder="Enter your Email"
                  name="phone"
                  value={values.phone}
                  onChange={handlechange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-6">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control mt-1"
                  placeholder="Enter your Email"
                  name="email"
                  value={values.email}
                  onChange={handlechange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="phoneNumber">Address</label>
                <textarea
                  id=""
                  cols="10"
                  rows="5"
                  className="form-control mt-1"
                  placeholder="Enter Your Address"
                  name="address"
                  value={values.address}
                  onChange={handlechange}
                ></textarea>
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-6">
                <label htmlFor="phoneNumber">Aadhar Card Number</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Your Aadhar Card Number"
                  value={values.aadhar}
                  name="aadhar"
                  onChange={handlechange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-6">
                <label htmlFor="phoneNumber">Pan No.</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Enter Your Pan Number"
                  value={values.pan}
                  name="pan"
                  onChange={handlechange}
                />
              </div>
              <div className="form-group form-wrapper-login mt-3 col-lg-12">
                <label htmlFor="phoneNumber">Bank Detail</label>
                <textarea
                  id=""
                  cols="10"
                  rows="5"
                  className="form-control mt-1"
                  placeholder="Enter Your Bank Details"
                  name="bank"
                  value={values.bank}
                  onChange={handlechange}
                ></textarea>
              </div>
              <p className="text-content mb-0 mt-3 text-center" style={{fontSize:"13px"}}>To update your details please contact our support team At support@bulwarkcapital.in</p>
              <div className="mt-3">
                <button type="submit" className="button-content">
               Contact Us
                </button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;

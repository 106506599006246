import React, { useEffect, useState } from 'react';
import Spinner from "../SpinnerData";
import api from "../../axios/api"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useSelector } from 'react-redux';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Steps from "../../components/StepsPage/Steps"
// import { useNavigate } from 'react-router-dom';
const KycQrCode = () => {
  const [dataQr,setOrData] = useState(null); 
  const [loadingdata, setLoadingData] = useState(false);
  let authToken = useSelector((state) => state.token.token);
  console.log("authToken data--------->",authToken);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingData(true);
        const response = await api.post('reverse-penny-drop',{},authToken);
        if(response.status===200){
            const result = await response.data;
            console.log("result=======>",result);
            setOrData(result); 
            console.log("dataQr===============>",dataQr.qr_code_image);
            toast.success("Yo are come in QR Code Data");
            setLoadingData(false);
        }
      } catch (error) {
        setLoadingData(false);
        toast.error("An error occurred while verifying PAN");
       } 
    };

    fetchData(); 
    // eslint-disable-next-line
  }, []); 
  const qrImg = dataQr?.qr_code_image ? `data:image/png;base64,${dataQr.qr_code_image}`: null;
  return (

    <>
    <Header/>
        <ToastContainer autoClose={5000} />
        {loadingdata && <Spinner/>}
        <div className="section-padding">
          <div className="container">
          
          <div className="row">
              <div className="col-lg-12">
                <Steps isKyc={true}/>
                <div className="text-center">
                {qrImg ? (
                  <img src={qrImg} alt="QR Code" className="QrCodeImage"/>
                ) : (
                  <p>No QR code available</p>
                )}
                </div>
              </div>
          </div>
          </div>
        </div>
      
<Footer/>
   
    </>
  );
};

export default KycQrCode;

import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
const LoanPage = () => {
  return (
    <>
      <Header />
      <div className="section-padding loan-wrapper-data padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="text-center">Loan Accounts</h4>
              <div className="table-wrapper-data-loan">
                <table class="table table-hover table-bordered border-danger">
                  <thead>
                    <tr>
                      <th scope="col">Loan Account Number</th>
                      <th scope="col">Loan Amount</th>
                      <th scope="col">Emi Amount</th>
                      <th scope="col">Loan Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>345</td>
                    </tr>
                    <tr>
                    
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                      <td>346</td>
                    </tr>
                    <tr>
                      <td>Larry the Bird</td>
                      <td>@twitter</td>
                      <td>@fat</td>
                      <td>348</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="m-auto text-center">
              <NavLink to="/pan-card"className="button-content text-white mt-4 text-center m-auto" style={{fontSize:"16px",width:"300px"}}>
                Check Eligibility In 15 Sec
                </NavLink>
              </div>
           
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoanPage;

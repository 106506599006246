import React from 'react'
import Steps from '../StepsPage/Steps'

const AgreementPage = () => {
  return (
    <div>
      <Steps isAgreement={true}/>
    </div>
  )
}

export default AgreementPage
